
// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import '../Styles/CurrentRaceScreens.css';
// import Clock from '../Modules/MathTime/Clock';
// import CreateRaces from '../Modules/CreateRaces';
// import { UnifiedPreloadProvider } from '../Modules/UnifiedPreloadContext';

// const CurrentRaceScreens = () => {
//   const navigate = useNavigate();

//   // Function to handle TV screen click
//   const handleTVScreenClick = () => {
//     navigate('/race'); // Navigate to the race screens component
//   };

//   return (
//     <UnifiedPreloadProvider>
//       {({ pics }) => {
//         // Default to an empty array if pics is undefined
//         const validPics = pics || [];

//         return (
//           <>
//             <div style={{ marginTop: '20vh', marginBottom: '-9%' }}>
//               <Clock />
//             </div>
//             {/* <div><CreateRaces/></div> Uncomment if needed */}
//             <div className="current-races" style={{ marginLeft: '20vw', marginTop: '-11vh', padding: '1%' }}>
//               {validPics.slice(0, 8).map((pic, index) => (
//                 <div
//                   key={index}
//                   className="television-screen"
//                   onClick={handleTVScreenClick}
//                   style={{ cursor: 'pointer' }}
//                 >
//                   <img
//                     src={pic.url} 
//                     alt={`Picture ${index + 1}`}
//                   />
//                 </div>
//               ))}
//             </div>
//           </>
//         );
//       }}
//     </UnifiedPreloadProvider>
//   );
// };

// export default CurrentRaceScreens;





import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../Styles/CurrentRaceScreens.css';
import Clock from '../Modules/MathTime/Clock';
import CreateRaces from '../Modules/CreateRaces';
import { UnifiedPreloadProvider } from '../Modules/UnifiedPreloadContext';

// Function to shuffle an array
const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const CurrentRaceScreens = () => {
  const navigate = useNavigate();

  // Function to handle TV screen click
  const handleTVScreenClick = () => {
    navigate('/race'); // Navigate to the race screens component
  };

  return (
    <UnifiedPreloadProvider>
      {({ pics }) => {
        // Default to an empty array if pics is undefined
        const validPics = pics || [];

        // Shuffle the pictures each time the component is rendered
        const shuffledPics = shuffleArray([...validPics]);

        return (
          <>
            <div style={{ marginTop: '20vh', marginBottom: '-9%' }}>
              <Clock />
            </div>
            {/* <div><CreateRaces/></div> Uncomment if needed */}
            <div className="current-races" style={{ marginLeft: '20vw', marginTop: '-11vh', padding: '1%' }}>
              {shuffledPics.slice(0, 8).map((pic, index) => (
                <div
                  key={index}
                  className="television-screen"
                  onClick={handleTVScreenClick}
                  style={{ cursor: 'pointer' }}
                >
                  <img
                    src={pic.url}
                    alt={`Picture ${index + 1}`}
                  />
                </div>
              ))}
            </div>
          </>
        );
      }}
    </UnifiedPreloadProvider>
  );
};

export default CurrentRaceScreens;
