// config.js - react

const ENV = process.env.NODE_ENV; // 'production' or 'development'

export const config = {
  backendUrl:
    ENV === 'production'
      ? process.env.REACT_APP_BACKEND_URL_PROD || 'http://hotponies.com'
      : process.env.REACT_APP_BACKEND_URL_DEV || 'http://localhost:3005',

  db: {
    user: process.env.DB_USER || 'defaultUser',
    password: process.env.DB_PASSWORD || 'defaultPassword',
    database: process.env.DB_DATABASE || 'defaultDatabase',
    port: parseInt(process.env.DB_PORT, 10) || 5432,
    host: process.env.DB_HOST || 'localhost',
  },

  jwtSecret: process.env.JWT_SECRET || 'defaultJwtSecret',
  sessionSecret: process.env.SESSION_SECRET || 'defaultSessionSecret',

  paths: {
    images: ENV === 'production' ? '/public/images' : '/public/images',
    videos: ENV === 'production' ? '/public/videos' : '/public/videos',
    pics: ENV === 'production' ? '/public/pics' : '/public/pics',
    documents: ENV === 'production' ? '/public/documents' : '/public/documents',
  },
};
