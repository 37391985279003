
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const CreateRaces = () => {
//   const baseURL = ' ${apiUrl}/api';

//   const [horseIds, setHorseIds] = useState([]);
//   const [jockeyIds, setJockeyIds] = useState([]);
//   const [horseNames, setHorseNames] = useState([]);
//   const [jockeyNames, setJockeyNames] = useState([]);
//   const [ownerIds, setOwnerIds] = useState([]);
//   const [ownerNames, setOwnerNames] = useState([]);
//   const [trainerIds, setTrainerIds] = useState([]);
//   const [trainerNames, setTrainerNames] = useState([]);
//   const [trackId, setTrackId] = useState(null);
//   const [trackName, setTrackName] = useState('');
//   const [raceId, setRaceId] = useState(null);
//   const [raceName, setRaceName] = useState('');

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         // Fetch 500 sequential primary key IDs and horse names from the /horses table
//         const horseResponse = await axios.get(`${baseURL}/horses?limit=500&order=id.asc`);
//         const horses = horseResponse.data;
//         const horseIds = horses.map((horse) => horse.id);
//         const horseNames = horses.map((horse) => horse.horse_name);
    
//         // Fetch 500 sequential primary key IDs and jockey names from the /jockeys table
//         const jockeyResponse = await axios.get(`${baseURL}/jockeys?limit=500&order=id.asc`);
//         const jockeys = jockeyResponse.data;
//         const jockeyIds = jockeys.map((jockey) => jockey.id);
//         const jockeyNames = jockeys.map((jockey) => jockey.jockey_name);
    
//         // Fetch primary key IDs and names for /owners
//         const ownerResponse = await axios.get(`${baseURL}/owners`);
//         const owners = ownerResponse.data;
//         const ownerIds = owners.map((owner) => owner.id);
//         const ownerNames = owners.map((owner) => owner.owner_name);
    
//         // Fetch primary key IDs and names for /trainers
//         const trainerResponse = await axios.get(`${baseURL}/trainers`);
//         const trainers = trainerResponse.data;
//         const trainerIds = trainers.map((trainer) => trainer.id);
//         const trainerNames = trainers.map((trainer) => trainer.trainer_name);
    
//         // Fetch a random track ID and track name
//         const trackResponse = await axios.get(`${baseURL}/tracks`);
//         const tracks = trackResponse.data;
//         const randomTrackIndex = getRandomInt(0, tracks.length - 1);
//         const track = tracks[randomTrackIndex];
//         const trackId = track.id;
//         const trackName = track.track_name;
    
//         // Fetch a random race ID and race name
//         const raceResponse = await axios.get(`${baseURL}/races`);
//         const races = raceResponse.data;
//         const randomRaceIndex = getRandomInt(0, races.length - 1);
//         const race = races[randomRaceIndex];
//         const raceId = race.id;
//         const raceName = race.race_name;
    
//         // Select the same number of IDs for horses and jockeys
//         const selectedCount = getRandomInt(6, 12);
//         const selectedHorseIds = getRandomItems(horseIds, selectedCount);
//         const selectedJockeyIds = getRandomItems(jockeyIds, selectedCount);
    
//         // Find the matching horse names and jockey names for the selected IDs
//         const selectedHorseNames = selectedHorseIds.map((id) =>
//           horseNames[horseIds.indexOf(id)]
//         );
//         const selectedJockeyNames = selectedJockeyIds.map((id) =>
//           jockeyNames[jockeyIds.indexOf(id)]
//         );
    
//         // Select random owner and trainer IDs
//         const selectedOwnerIds = getRandomItems(ownerIds, selectedCount);
//         const selectedTrainerIds = getRandomItems(trainerIds, selectedCount);
    
//         // Find the matching owner names and trainer names for the selected IDs
//         const selectedOwnerNames = selectedOwnerIds.map((id) =>
//           ownerNames[ownerIds.indexOf(id)]
//         );
//         const selectedTrainerNames = selectedTrainerIds.map((id) =>
//           trainerNames[trainerIds.indexOf(id)]
//         );
        
//         console.log(
//           'track-race-jockey-owner:',
//           trackResponse.data,
//           raceResponse.data,
//           jockeyResponse.data,
//           ownerResponse.data
//         );
        
//         // Update the state with the selected IDs and names
//         setHorseIds(selectedHorseIds);
//         setJockeyIds(selectedJockeyIds);
//         setHorseNames(selectedHorseNames);
//         setJockeyNames(selectedJockeyNames);
//         setOwnerIds(selectedOwnerIds);
//         setOwnerNames(selectedOwnerNames);
//         setTrainerIds(selectedTrainerIds);
//         setTrainerNames(selectedTrainerNames);
//         setTrackId(trackId);
//         setTrackName(trackName);
//         setRaceId(raceId);
//         setRaceName(raceName);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };
    

//     fetchData();
//   }, []);

//   // Helper function to generate a random integer between min and max (inclusive)
//   const getRandomInt = (min, max) => {
//     return Math.floor(Math.random() * (max - min + 1)) + min;
//   };

//   // Helper function to select random items from an array
//   const getRandomItems = (array, count) => {
//     const shuffledArray = array.sort(() => 0.5 - Math.random());
//     return shuffledArray.slice(0, count);
//   };

//   return (
//     <div>
//       <h2 style={{textAlign: 'center'}}>Create Races</h2>
//       <h2>Track Name === :  {trackName} -- & -- Race Name === :  {raceName}</h2>
//       <table>
//         <thead>
//           <tr>
//             <th style={{ fontSize: '12px' }} >Owner Name</th>
//             <th style={{ fontSize: '12px' }} >Trainer Name</th>
//             <th style={{ fontSize: '12px' }} >Horse Name</th>
//             <th style={{ fontSize: '12px' }} >Jockey Name</th>
//           </tr>
//         </thead>
//         <tbody>
//           {horseNames.map((horseName, index) => (
//             <tr key={index}>
//               <td style={{ fontSize: '10px' }} >{ownerNames[index]}</td>
//               <td style={{ fontSize: '10px' }} >{trainerNames[index]}</td>
//               <td style={{ fontSize: '10px' }} >{horseNames[index]}</td>
//               <td style={{ fontSize: '10px' }} >{jockeyNames[index]}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default CreateRaces;





// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const CreateRaces = () => {
//   const baseURL = ' ${apiUrl}/api';

//   const [horseIds, setHorseIds] = useState([]);
//   const [jockeyIds, setJockeyIds] = useState([]);
//   const [horseNames, setHorseNames] = useState([]);
//   const [jockeyNames, setJockeyNames] = useState([]);
//   const [ownerIds, setOwnerIds] = useState([]);
//   const [ownerNames, setOwnerNames] = useState([]);
//   const [trainerIds, setTrainerIds] = useState([]);
//   const [trainerNames, setTrainerNames] = useState([]);
//   const [trackId, setTrackId] = useState(null);
//   const [trackName, setTrackName] = useState('');
//   const [raceId, setRaceId] = useState(null);
//   const [raceName, setRaceName] = useState('');

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         // Fetch 500 sequential primary key IDs and horse names from the /horses table
//         const horseResponse = await axios.get(`${baseURL}/horses?limit=500&order=id.asc`);
//         const horses = horseResponse.data;
//         const horseIds = horses.map((horse) => horse.id);
//         const horseNames = horses.map((horse) => horse.horse_name);
    
//         // Fetch 500 sequential primary key IDs and jockey names from the /jockeys table
//         const jockeyResponse = await axios.get(`${baseURL}/jockeys?limit=500&order=id.asc`);
//         const jockeys = jockeyResponse.data;
//         const jockeyIds = jockeys.map((jockey) => jockey.id);
//         const jockeyNames = jockeys.map((jockey) => jockey.jockey_name);
    
//         // Fetch primary key IDs and names for /owners
//         const ownerResponse = await axios.get(`${baseURL}/owners`);
//         const owners = ownerResponse.data;
//         const ownerIds = owners.map((owner) => owner.id);
//         const ownerNames = owners.map((owner) => owner.owner_name);
    
//         // Fetch primary key IDs and names for /trainers
//         const trainerResponse = await axios.get(`${baseURL}/trainers`);
//         const trainers = trainerResponse.data;
//         const trainerIds = trainers.map((trainer) => trainer.id);
//         const trainerNames = trainers.map((trainer) => trainer.trainer_name);
    
//         // Fetch a random track ID and track name
//         const trackResponse = await axios.get(`${baseURL}/tracks`);
//         const tracks = trackResponse.data;
//         const randomTrackIndex = getRandomInt(0, tracks.length - 1);
//         const track = tracks[randomTrackIndex];
//         const trackId = track.id;
//         const trackName = track.track_name;
    
//         // Fetch a random race ID and race name
//         const raceResponse = await axios.get(`${baseURL}/races`);
//         const races = raceResponse.data;
//         const randomRaceIndex = getRandomInt(0, races.length - 1);
//         const race = races[randomRaceIndex];
//         const raceId = race.id;
//         const raceName = race.race_name;
    
//         // Select the same number of IDs for horses and jockeys
//         const selectedCount = getRandomInt(6, 12);
//         const selectedHorseIds = getRandomItems(horseIds, selectedCount);
//         const selectedJockeyIds = getRandomItems(jockeyIds, selectedCount);
    
//         // Find the matching horse names and jockey names for the selected IDs
//         const selectedHorseNames = selectedHorseIds.map((id) =>
//           horseNames[horseIds.indexOf(id)]
//         );
//         const selectedJockeyNames = selectedJockeyIds.map((id) =>
//           jockeyNames[jockeyIds.indexOf(id)]
//         );
    
//         // Select random owner and trainer IDs
//         const selectedOwnerIds = getRandomItems(ownerIds, selectedCount);
//         const selectedTrainerIds = getRandomItems(trainerIds, selectedCount);
    
//         // Find the matching owner names and trainer names for the selected IDs
//         const selectedOwnerNames = selectedOwnerIds.map((id) =>
//           ownerNames[ownerIds.indexOf(id)]
//         );
//         const selectedTrainerNames = selectedTrainerIds.map((id) =>
//           trainerNames[trainerIds.indexOf(id)]
//         );
        
//         console.log(
//           'track-race-jockey-owner:',
//           trackResponse.data,
//           raceResponse.data,
//           jockeyResponse.data,
//           ownerResponse.data
//         );
        
//         // Update the state with the selected IDs and names
//         setHorseIds(selectedHorseIds);
//         setJockeyIds(selectedJockeyIds);
//         setHorseNames(selectedHorseNames);
//         setJockeyNames(selectedJockeyNames);
//         setOwnerIds(selectedOwnerIds);
//         setOwnerNames(selectedOwnerNames);
//         setTrainerIds(selectedTrainerIds);
//         setTrainerNames(selectedTrainerNames);
//         setTrackId(trackId);
//         setTrackName(trackName);
//         setRaceId(raceId);
//         setRaceName(raceName);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };
    

//     fetchData();
//   }, []);

//   // Helper function to generate a random integer between min and max (inclusive)
//   const getRandomInt = (min, max) => {
//     return Math.floor(Math.random() * (max - min + 1)) + min;
//   };

//   // Helper function to select random items from an array
//   const getRandomItems = (array, count) => {
//     const shuffledArray = array.sort(() => 0.5 - Math.random());
//     return shuffledArray.slice(0, count);
//   };

//   return (
//     <div>
//       <h2 style={{textAlign: 'center'}}>Create Races</h2>
//       <h2>Track Name === :  {trackName} -- & -- Race Name === :  {raceName}</h2>
//       <table>
//         <thead>
//           <tr>
//             <th style={{ fontSize: '12px' }} >Owner Name</th>
//             <th style={{ fontSize: '12px' }} >Trainer Name</th>
//             <th style={{ fontSize: '12px' }} >Horse Name</th>
//             <th style={{ fontSize: '12px' }} >Jockey Name</th>
//           </tr>
//         </thead>
//         <tbody>
//           {horseNames.map((horseName, index) => (
//             <tr key={index}>
//               <td style={{ fontSize: '10px' }} >{ownerNames[index]}</td>
//               <td style={{ fontSize: '10px' }} >{trainerNames[index]}</td>
//               <td style={{ fontSize: '10px' }} >{horseNames[index]}</td>
//               <td style={{ fontSize: '10px' }} >{jockeyNames[index]}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default CreateRaces;


import React, { useEffect, useState } from 'react';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const CreateRaces = () => {
  const baseURL = ' ${apiUrl}/api';

  const [horseIds, setHorseIds] = useState([]);
  const [jockeyIds, setJockeyIds] = useState([]);
  const [horseNames, setHorseNames] = useState([]);
  const [jockeyNames, setJockeyNames] = useState([]);
  const [ownerIds, setOwnerIds] = useState([]);
  const [ownerNames, setOwnerNames] = useState([]);
  const [trainerIds, setTrainerIds] = useState([]);
  const [trainerNames, setTrainerNames] = useState([]);
  const [trackId, setTrackId] = useState(null);
  const [trackName, setTrackName] = useState('');
  const [raceId, setRaceId] = useState(null);
  const [raceName, setRaceName] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch 500 sequential primary key IDs and horse names from the /horses table
        const horseResponse = await axios.get(`${baseURL}/horses?limit=500&order=id.asc`);
        const horses = horseResponse.data;
        const horseIds = horses.map((horse) => horse.id);
        const horseNames = horses.map((horse) => horse.horse_name);

        // Fetch 500 sequential primary key IDs and jockey names from the /jockeys table
        const jockeyResponse = await axios.get(`${baseURL}/jockeys?limit=500&order=id.asc`);
        const jockeys = jockeyResponse.data;
        const jockeyIds = jockeys.map((jockey) => jockey.id);
        const jockeyNames = jockeys.map((jockey) => jockey.jockey_name);

        // Fetch primary key IDs and names for /owners
        const ownerResponse = await axios.get(`${baseURL}/owners`);
        const owners = ownerResponse.data;
        const ownerIds = owners.map((owner) => owner.id);
        const ownerNames = owners.map((owner) => owner.owner_name);

        // Fetch primary key IDs and names for /trainers
        const trainerResponse = await axios.get(`${baseURL}/trainers`);
        const trainers = trainerResponse.data;
        const trainerIds = trainers.map((trainer) => trainer.id);
        const trainerNames = trainers.map((trainer) => trainer.trainer_name);

        // Fetch a random track ID and track name
        const trackResponse = await axios.get(`${baseURL}/tracks`);
        const tracks = trackResponse.data;
        const randomTrackIndex = getRandomInt(0, tracks.length - 1);
        const track = tracks[randomTrackIndex];
        const trackId = track.id;
        const trackName = track.track_name;

        // Fetch a random race ID and race name
        const raceResponse = await axios.get(`${baseURL}/races`);
        const races = raceResponse.data;
        const randomRaceIndex = getRandomInt(0, races.length - 1);
        const race = races[randomRaceIndex];
        const raceId = race.id;
        const raceName = race.race_name;

        // Select the same number of IDs for horses and jockeys
        const selectedCount = getRandomInt(6, 12);
        const selectedHorseIds = getRandomItems(horseIds, selectedCount);
        const selectedJockeyIds = getRandomItems(jockeyIds, selectedCount);

        // Find the matching horse names and jockey names for the selected IDs
        const selectedHorseNames = selectedHorseIds.map((id) =>
          horseNames[horseIds.indexOf(id)]
        );
        const selectedJockeyNames = selectedJockeyIds.map((id) =>
          jockeyNames[jockeyIds.indexOf(id)]
        );

        // Select random owner and trainer IDs
        const selectedOwnerIds = getRandomItems(ownerIds, selectedCount);
        const selectedTrainerIds = getRandomItems(trainerIds, selectedCount);

        // Find the matching owner names and trainer names for the selected IDs
        const selectedOwnerNames = selectedOwnerIds.map((id) =>
          ownerNames[ownerIds.indexOf(id)]
        );
        const selectedTrainerNames = selectedTrainerIds.map((id) =>
          trainerNames[trainerIds.indexOf(id)]
        );

        console.log(
          'track-race-jockey-owner:',
          trackResponse.data,
          raceResponse.data,
          jockeyResponse.data,
          ownerResponse.data
        );

        // Update the state with the selected IDs and names
        setHorseIds(selectedHorseIds);
        setJockeyIds(selectedJockeyIds);
        setHorseNames(selectedHorseNames);
        setJockeyNames(selectedJockeyNames);
        setOwnerIds(selectedOwnerIds);
        setOwnerNames(selectedOwnerNames);
        setTrainerIds(selectedTrainerIds);
        setTrainerNames(selectedTrainerNames);
        setTrackId(trackId);
        setTrackName(trackName);
        setRaceId(raceId);
        setRaceName(raceName);

        // Write data to the created_races table
        const raceData = {
          race_name: raceName,
          track_name: trackName,
          horse_id1: selectedHorseIds[0],
          horse_id2: selectedHorseIds[1],
          horse_id3: selectedHorseIds[2],
          horse_id4: selectedHorseIds[3],
          horse_id5: selectedHorseIds[4],
          horse_id6: selectedHorseIds[5],
          horse_id7: selectedHorseIds[6],
          horse_id8: selectedHorseIds[7],
          horse_id9: selectedHorseIds[8],
          horse_id10: selectedHorseIds[9],
          horse_id11: selectedHorseIds[10],
          horse_id12: selectedHorseIds[11],
          jockey_id1: selectedJockeyIds[0],
          jockey_id2: selectedJockeyIds[1],
          jockey_id3: selectedJockeyIds[2],
          jockey_id4: selectedJockeyIds[3],
          jockey_id5: selectedJockeyIds[4],
          jockey_id6: selectedJockeyIds[5],
          jockey_id7: selectedJockeyIds[6],
          jockey_id8: selectedJockeyIds[7],
          jockey_id9: selectedJockeyIds[8],
          jockey_id10: selectedJockeyIds[9],
          jockey_id11: selectedJockeyIds[10],
          jockey_id12: selectedJockeyIds[11],
          owner_id1: selectedOwnerIds[0],
          owner_id2: selectedOwnerIds[1],
          owner_id3: selectedOwnerIds[2],
          owner_id4: selectedOwnerIds[3],
          owner_id5: selectedOwnerIds[4],
          owner_id6: selectedOwnerIds[5],
          owner_id7: selectedOwnerIds[6],
          owner_id8: selectedOwnerIds[7],
          owner_id9: selectedOwnerIds[8],
          owner_id10: selectedOwnerIds[9],
          owner_id11: selectedOwnerIds[10],
          owner_id12: selectedOwnerIds[11],
          trainer_id1: selectedTrainerIds[0],
          trainer_id2: selectedTrainerIds[1],
          trainer_id3: selectedTrainerIds[2],
          trainer_id4: selectedTrainerIds[3],
          trainer_id5: selectedTrainerIds[4],
          trainer_id6: selectedTrainerIds[5],
          trainer_id7: selectedTrainerIds[6],
          trainer_id8: selectedTrainerIds[7],
          trainer_id9: selectedTrainerIds[8],
          trainer_id10: selectedTrainerIds[9],
          trainer_id11: selectedTrainerIds[10],
          trainer_id12: selectedTrainerIds[11],
        };

        await axios.post(`${baseURL}/created_races`, raceData);
        console.log('Data written to created_races table.');
      } catch (error) {
        console.log('Error:', error);
      }
    };

    fetchData();
  }, []);

  const getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const getRandomItems = (items, count) => {
    const shuffled = items.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  return (
    <div>
      <h2 style={{ textAlign: 'center' }}>Current Race</h2>
      <h3>  {trackName}  - {raceName}</h3>
      <table>
        <thead>
          <tr>
            <th style={{ fontSize: '12px' }} >Pos #</th>
            <th style={{ fontSize: '12px' }} >Owner</th>
            <th style={{ fontSize: '12px' }} >Trainer</th>
            <th style={{ fontSize: '12px' }} >Horse</th>
            <th style={{ fontSize: '12px' }} >Jockey</th>
            <th style={{ fontSize: '12px' }} >Odds</th>
          </tr>
        </thead>
        <tbody >
          {horseNames.map((horseName, index) => (
            <tr key={index} >
              <td style={{ fontSize: '10px', lineHeight: '12px' }} >{index + 1}</td> {/* Display the line number */}
              <td style={{ fontSize: '10px', lineHeight: '12px' }} >{horseNames[index]}</td>
              <td style={{ fontSize: '10px' , lineHeight: '12px'}} >{jockeyNames[index]}</td>
              <td style={{ fontSize: '10px', lineHeight: '12px'}} >{trainerNames[index]}</td>
              <td style={{ fontSize: '10px', lineHeight: '12px' }} >{ownerNames[index]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
  
    };
    
    export default CreateRaces;
    