import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import App from "./App";
import "./index.css";
import { RaceProvider } from "./Components/Pages/RaceContext";
import AuthProvider from "./Components/Modules/Auth/AuthContext";
import { PreLoadProvider } from "./Components/Pages/PreLoadContext"; // Use curly braces here

const queryClient = new QueryClient();

const RootComponent = () => {
  const [triggerLoad, setTriggerLoad] = useState(true);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <RaceProvider>
          <PreLoadProvider triggerLoad={triggerLoad}>
            <App setTriggerLoad={setTriggerLoad} />
          </PreLoadProvider>
        </RaceProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RootComponent />);
