import React, { useState, useEffect, useContext } from "react";
import "../../Styles/Clock.css";
import { RaceContext } from '../../Pages/RaceContext';
import CalculateStartTimes from "./CalculateStartTimes";

function Clock({ showCountdown }) {
  const { trackId, postTime } = useContext(RaceContext);
  const [countdown, setCountdown] = useState(0);
  const [targetTime, setTargetTime] = useState(null);

  const initializeClock = async () => {
    if (trackId) {
      try {
        const nextRaceTime = await CalculateStartTimes(trackId);
        const parsedTime = new Date(nextRaceTime);
  
        if (!isNaN(parsedTime.getTime())) {
          setTargetTime(parsedTime);
        } else {
          // console.warn("Invalid nextRaceTime returned, setting default time to 12:00 PM.");
          setDefaultTargetTime(parsedTime);
        }
      } catch (error) {
        console.error("Error initializing clock:", error);
        setDefaultTargetTime();
      }
    } else {
      // console.warn("No trackId provided, setting default time to 12:00 PM.");
      setDefaultTargetTime();
    }
  };
  
  // Helper function to set default time to 12:00 PM
  const setDefaultTargetTime = () => {
    const now = new Date();
    const defaultTime = new Date();
    defaultTime.setHours(12, 0, 0, 0); // Set to 12:00:00.000 PM
  
    // If it's past 12:00 PM today, set the default time to tomorrow
    if (now > defaultTime) {
      defaultTime.setDate(now.getDate() + 1);
    }
  
    setTargetTime(defaultTime);
  };
  

  useEffect(() => {
    setCountdown(0);
    setTargetTime(null);
    initializeClock();
  }, [trackId]);

  useEffect(() => {
    const updateCountdown = () => {
      const now = new Date();
      let nextPostTime = postTime instanceof Date ? postTime : new Date(postTime);

      if (nextPostTime <= now) {
        nextPostTime.setDate(now.getDate() + 1);
      }

      const remainingTime = nextPostTime.getTime() - now.getTime();
      setCountdown(remainingTime > 0 ? remainingTime : 0);
    };

    const intervalId = setInterval(updateCountdown, 1000);
    return () => clearInterval(intervalId);
  }, [postTime]);

  useEffect(() => {
    const checkAndReinitialize = () => {
      if (!targetTime || isNaN(targetTime.getTime())) {
        console.warn("Invalid targetTime, reinitializing clock...");
        initializeClock();
      }
    };

    const timerId = setTimeout(checkAndReinitialize, 2000); // Adjusted delay
    return () => clearTimeout(timerId);
  }, [targetTime]);

  const totalSeconds = Math.floor(countdown / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return (
    <div className="clock-container" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
      <div style={{ flex: '1 1 40%', marginTop: '1vh' }}>
        <p id="time">Time: {new Date().toLocaleTimeString()}</p>
        <p id="start-time">Start time: {targetTime ? targetTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) : "Loading..."}</p>
      </div>
      <div style={{ flex: '1 1 60%', textAlign: 'right' }}>
        <p id="post-time">Post time: {postTime ? new Date(postTime).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) : "Loading..."}</p>
        {showCountdown && (
          <p id="race-begins">
            Race begins: {hours > 0 ? `${hours} hrs ` : ""}
            {minutes} mins {seconds} secs
          </p>
        )}
      </div>
    </div>
  );
}

export default Clock;
