
// auth.js

// import axios from 'axios';

// const API_BASE_URL = ' ${apiUrl}/api'; // Update with your actual API base URL

// // Function to authenticate user by sending credentials to the server
// export const authenticateUser = async (username, password) => {
//   try {
//     // Send a POST request to the server for authentication
//     const response = await axios.post(`${API_BASE_URL}/auth/login`, { username, password });

//     // Check if authentication was successful
//     if (response.data.success) {
//       // Extract the token from the response
//       const { token } = response.data;

//       // Store the token in local storage
//       localStorage.setItem('token', token);

//       // Set the authentication token for future requests
//       setAuthToken(token);

//       return { success: true };
//     } else {
//       // Authentication failed, return an error message
//       return { success: false, error: 'Invalid credentials' };
//     }
//   } catch (error) {
//     // Handle errors, such as network issues
//     console.error('Error during authentication:', error);
//     return { success: false, error: 'Internal server error' };
//   }
// };

// // Function to set the authentication token in the request headers
// export const setAuthToken = (token) => {
//   if (token) {
//     // Apply the token to all axios requests
//     axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
//   } else {
//     // Remove the token if it exists (e.g., during logout)
//     delete axios.defaults.headers.common['Authorization'];
//   }
// };

// // Function to check if the user is authenticated
// export const isAuthenticated = () => {
//   // Check if the authentication token exists in local storage
//   const token = localStorage.getItem('token');

//   return !!token; // Return true if the token exists, indicating the user is authenticated
// };


/////////////////////////////////////////////////////////////////////////////////////

// This React component is designed to handle the Sensi-QR (sensipass) authentication process based on 
// an email parameter provided in the URL. It fetches user data from the server, performs login actions, 
// and redirects the user accordingly. If no email is present or if there are errors during the process, 
// it redirects  to the registration page. The specific behavior might depend on the actual implementation 
// of the missing utility functions and the server API.


import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { setAuthToken } from './authUtils'; // Update the path based on your file structure
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const Auth = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("id");
  const navigate = useNavigate();

  useEffect(() => {
    const loginUserWithEmail = async () => {
      try {
        // Fetch user data based on the provided email
        const response = await axios.get(`${apiUrl}/api/users?email=${email}`);
        const user = response.data[0]; // Assuming there is only one user with the given email

        if (user) {
          // Perform login actions (similar to your handleLogin logic)
          sessionStorage.setItem("pUuid", user.uuid);
          sessionStorage.setItem("userEmail", user.email);

          // Set the authentication token for future requests
          setAuthToken(user.uuid);

          // Mark the user as logged in and navigate to the "program-card" page
          navigate('/program-card');
        } else {
          // Redirect to the home page if no user found
          navigate('/register');
        }
      } catch (error) {
        console.error("Error during login:", error);
        // Handle errors, such as network issues
        navigate('/register');
      }
    };

    // Check if email is provided in the URL
    if (email) {
      loginUserWithEmail();
    } else {
      // Redirect to the home page if no email found
      navigate('/register');
    }
  }, [email, navigate]);

  return (
    <div>
      {/* Your component JSX goes here */}
      {/* You can render loading states or other content if needed */}
    </div>
  );
};

export default Auth;
