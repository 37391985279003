
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Clock from '../Modules/MathTime/Clock'; // Ensure correct import path


const apiUrl = process.env.REACT_APP_API_BASE_URL;


function RaceDetails({ trackId }) {
  const [trackDetails, setTrackDetails] = useState(null);
  const [races, setRaces] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTrackDetails = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/tracks/${trackId}`);
        const data = Array.isArray(response.data) ? response.data[0] : response.data;
        setTrackDetails(data);
        console.log('Fetched track details:', data);

        const raceIDs = [];
        const raceDates = data.race_date || [];
        for (let i = 1; i <= 13; i++) {
          const raceID = data[`race_id${i}`];
          if (raceID) {
            raceIDs.push(raceID);
          }
        }
        console.log('Fetched race IDs:', raceIDs);

        if (raceIDs.length === 0) {
          console.log('No race IDs found');
          return;
        }

        const updates = raceIDs.map((raceID, index) => {
          const trackRaceDate = raceDates[index] && raceDates[index].split('T')[0];
          if (trackRaceDate) {
            console.log(`Updating race ID ${raceID} date to ${trackRaceDate}`);
            return axios.put(`${apiUrl}/api/races/${raceID}`, { race_date: trackRaceDate });
          }
          return null;
        }).filter(update => update !== null);
        await Promise.all(updates);
        console.log('Updated race dates');

        const updatedRaceDetailsPromises = raceIDs.map(id => axios.get(`${apiUrl}/api/races/${id}`).then(res => res.data));
        const updatedRaceDetails = await Promise.all(updatedRaceDetailsPromises);
        setRaces(updatedRaceDetails.flat());
        console.log('Fetched updated race details:', updatedRaceDetails.flat());

      } catch (error) {
        setError(error.message);
        console.error('Error fetching track or race details:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTrackDetails();
  }, [trackId]);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
    console.log('Selected date changed:', event.target.value);
  };

  const filteredRaces = selectedDate
    ? races.filter(race => {
        const raceDate = race.race_date && race.race_date.split('T')[0];
        return raceDate === selectedDate;
      })
    : [];

  console.log('trackDetails:', trackDetails);
  console.log('races:', races);
  console.log('selectedDate:', selectedDate);
  console.log('filteredRaces:', filteredRaces);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!trackDetails) return <div>No track details available.</div>;

  const nextRaceTime = trackDetails.race_date ? trackDetails.race_date[0] : null;
  const startTime = trackDetails.start_time ? new Date(`1970-01-01T${trackDetails.start_time}Z`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : null;

  return (
    <div>
      <h1>Race Details for {trackDetails.track_name}</h1>
      <Clock trackId={trackId} nextRaceTime={nextRaceTime} />
      
      <h2>Track Details</h2>
      <table>
        <thead>
          <tr>
            <th>Track Name</th>
            <th>Location</th>
            <th>Race Schedule</th>
            <th>Race Dates</th>
            <th>Start Time</th>
            <th>Surface Type</th>
            <th>Wager Types</th>
            <th>Purse Sizes</th>
            <th>Weather Conditions</th>
            <th>Track Condition</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{trackDetails.track_name}</td>
            <td>{trackDetails.location}</td>
            <td>{trackDetails.race_schedule ? trackDetails.race_schedule.join(', ') : 'N/A'}</td>
            <td>
              <select onChange={handleDateChange} value={selectedDate || ''}>
                <option value="">Select a date</option>
                {trackDetails.race_date && trackDetails.race_date.map(date => (
                  <option key={date} value={date.split('T')[0]}>{new Date(date).toLocaleDateString()}</option>
                ))}
              </select>
            </td>
            <td>{startTime ? startTime : 'Not set'}</td>
            <td>{trackDetails.surface_type}</td>
            <td>{trackDetails.wager_types}</td>
            <td>{trackDetails.purse_sizes}</td>
            <td>{trackDetails.weather}</td>
            <td>{trackDetails.track_condition}</td>
          </tr>
        </tbody>
      </table>
  
      {selectedDate && (
        <>
          <h2>Race Details for {new Date(selectedDate + 'T00:00:00').toLocaleDateString()}</h2>
          <table>
            <thead>
              <tr>
                <th>Race ID</th>
                <th>Race Name</th>
                <th>Race Date</th>
                <th>Starting Time</th>
                <th>Race Class</th>
                <th>Number of Horses</th>
              </tr>
            </thead>
            <tbody>
              {filteredRaces.map(race => (
                <tr key={race.id}>
                  <td>{race.id}</td>
                  <td>{race.race_name}</td>
                  <td>{new Date(race.race_date).toLocaleDateString()}</td>
                  <td>{race.starting_time ? new Date(`1970-01-01T${race.starting_time}Z`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : 'N/A'}</td>
                  <td>{race.race_class}</td>
                  <td>{race.num_horses}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}

export default RaceDetails;
