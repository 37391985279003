
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';


// const apiUrl = process.env.REACT_APP_API_BASE_URL;


// function DataDisplay({ data, selectedColumn, onDelete }) {
//   if (data && selectedColumn) {
//     return (
//       <div>
//         <h2>Data for Column: {selectedColumn}</h2>
//         <ul>
//           {Array.isArray(data) && data.map(row => ( // Ensure data is an array
//             <li key={row.id}>
//               {row[selectedColumn]}
//               <button onClick={() => onDelete(row.id)}>Delete</button>
//             </li>
//           ))}
//         </ul>
//       </div>
//     );
//   }
//   return null;
// }


// function EndPoint() {
//   const [tables, setTables] = useState([]);
//   const [selectedTable, setSelectedTable] = useState('');
//   const [selectedColumn, setSelectedColumn] = useState('');
//   const [searchValue, setSearchValue] = useState('');
//   const [data, setData] = useState(null);
//   const [columns, setColumns] = useState([]);

//   useEffect(() => {
//     const fetchTables = async () => {
//       try {
//         const response = await axios.get(`${apiUrl}/api/tables`); // Correct interpolation
//         setTables(response.data);
//       } catch (error) {
//         console.error(error);
//       }
//     };
    
//     fetchTables();
//   }, []);

//   useEffect(() => {
//     const fetchColumns = async () => {
//       if (selectedTable) {
//         try {
//           const response = await axios.get(`${apiUrl}/api/${selectedTable}`);
//           const tableData = response.data;
//           const columns = tableData.length > 0 ? Object.keys(tableData[0]) : [];
//           setColumns(columns);
//           setSelectedColumn(columns[0] || '');
//         } catch (error) {
//           console.error(error);
//         }
//       } else {
//         setColumns([]);
//         setSelectedColumn('');
//       }
//     };
    
    

//     fetchColumns();
//   }, [selectedTable]);

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(`${apiUrl}/api/${selectedTable}`);
//       setData(response.data);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const filterData = () => {
//     if (data && selectedColumn && searchValue) {
//       const filteredData = data.filter(row => row[selectedColumn] === searchValue);
//       return filteredData.map(row => (
//         <li key={row.id}>
//           {Object.entries(row).map(([key, value]) => (
//             <span key={key}>{key}: {value}</span>
//           ))}
//         </li>
//       ));
//     }
//     return null;
//   };

//   const createData = async () => {
//     try {
//       const newRow = { [selectedColumn]: searchValue };
//       await axios.post(`${apiUrl}/api/${selectedTable}`, newRow);
//       fetchData();
//       setSearchValue('');
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const updateData = async (id, value) => {
//     try {
//       const updatedRow = { [selectedColumn]: value };
//       await axios.put(`${apiUrl}/api/${selectedTable}/${id}`, updatedRow);
//       fetchData();
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const deleteData = async (id) => {
//     try {
//       await axios.delete(`${apiUrl}/api/${selectedTable}/${id}`);
//       fetchData();
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   return (
//     <div  style={{ marginTop: '12%' }}>
//       <div >
//         <select value={selectedTable} onChange={(e) => setSelectedTable(e.target.value)}>
//           <option value="">Select a table</option>
//           {tables.map((table) => (
//             <option key={table} value={table}>{table}</option>
//           ))}
//         </select>
//         <div>
//           <select value={selectedColumn} onChange={(e) => setSelectedColumn(e.target.value)}>
//             {columns.map((column) => (
//               <option key={column} value={column}>{column}</option>
//             ))}
//           </select>
//         </div>
//         <input
//           type="text"
//           placeholder="Search Value"
//           value={searchValue}
//           onChange={(e) => setSearchValue(e.target.value)}
//         />
//         <button onClick={fetchData}>Fetch Data</button>
//         <button onClick={createData}>Create Data</button>
//       </div>

//       {data && (
//         <div>
//           <h2>Filtered Data:</h2>
//           <ul>
//             {filterData()}
//           </ul>
//         </div>
//       )}

//       <DataDisplay data={data} selectedColumn={selectedColumn} onDelete={deleteData} />
//     </div>
//   );
// }

// export default EndPoint;



import React, { useState, useEffect } from 'react';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

function DataDisplay({ data, selectedColumn, onDelete }) {
  if (data && selectedColumn) {
    return (
      <div>
        <h2>Data for Column: {selectedColumn}</h2>
        <ul>
          {Array.isArray(data) && data.map(row => (
            <li key={row.id}>
              {row[selectedColumn]}{' '}
              <button onClick={() => onDelete(row.id)}>Delete</button>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  return null;
}

function EndPoint() {
  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState('');
  const [selectedColumn, setSelectedColumn] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState(null);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    const fetchTables = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/tables`);
        setTables(response.data);
      } catch (error) {
        console.error('Error fetching tables:', error);
      }
    };

    fetchTables();
  }, []);

  useEffect(() => {
    const fetchColumns = async () => {
      if (selectedTable) {
        try {
          const response = await axios.get(`${apiUrl}/api/${selectedTable}`);
          const tableData = response.data;
          const columns = tableData.length > 0 ? Object.keys(tableData[0]) : [];
          setColumns(columns);
          setSelectedColumn(columns[0] || '');
        } catch (error) {
          console.error('Error fetching columns:', error);
        }
      } else {
        setColumns([]);
        setSelectedColumn('');
      }
    };

    fetchColumns();
  }, [selectedTable]);

  const fetchData = async () => {
    if (!selectedTable) {
      console.error('No table selected');
      return;
    }

    try {
      const response = await axios.get(`${apiUrl}/api/${selectedTable}`);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const filterData = () => {
    if (data && selectedColumn && searchValue) {
      const filteredData = data.filter(row => row[selectedColumn]?.toString() === searchValue);
      return filteredData.map(row => (
        <li key={row.id}>
          {Object.entries(row).map(([key, value]) => (
            <span key={key}>{key}: {value} </span>
          ))}
        </li>
      ));
    }
    return null;
  };

  const createData = async () => {
    if (!selectedTable || !selectedColumn || !searchValue) {
      console.error('Missing required fields to create data');
      return;
    }

    try {
      const newRow = { [selectedColumn]: searchValue };
      await axios.post(`${apiUrl}/api/${selectedTable}`, newRow);
      fetchData();
      setSearchValue('');
    } catch (error) {
      console.error('Error creating data:', error);
    }
  };

  const updateData = async (id, value) => {
    if (!selectedTable || !selectedColumn || !id) {
      console.error('Missing required fields to update data');
      return;
    }

    try {
      const updatedRow = { [selectedColumn]: value };
      await axios.put(`${apiUrl}/api/${selectedTable}/${id}`, updatedRow);
      fetchData();
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  const deleteData = async (id) => {
    if (!selectedTable || !id) {
      console.error('Missing required fields to delete data');
      return;
    }

    try {
      await axios.delete(`${apiUrl}/api/${selectedTable}/${id}`);
      fetchData();
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  return (
    <div style={{ marginTop: '12%' }}>
      <div>
        <select value={selectedTable} onChange={(e) => setSelectedTable(e.target.value)}>
          <option value="">Select a table</option>
          {tables.map((table) => (
            <option key={table} value={table}>{table}</option>
          ))}
        </select>

        {columns.length > 0 && (
          <div>
            <select value={selectedColumn} onChange={(e) => setSelectedColumn(e.target.value)}>
              {columns.map((column) => (
                <option key={column} value={column}>{column}</option>
              ))}
            </select>
          </div>
        )}

        <input
          type="text"
          placeholder="Search Value"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />

        <button onClick={fetchData}>Fetch Data</button>
        <button onClick={createData}>Create Data</button>
      </div>

      {data && (
        <div>
          <h2>Filtered Data:</h2>
          <ul>
            {filterData()}
          </ul>
        </div>
      )}

      <DataDisplay data={data} selectedColumn={selectedColumn} onDelete={deleteData} />
    </div>
  );
}

export default EndPoint;
