
import React, { useContext, useState, useEffect } from 'react';
import { FreeContext } from '../Pages/FreeContext';
import FreeRandomData from '../Modules/FreeRandomData';
import { useNavigate } from 'react-router-dom';
import FormatKey from '../Modules/FormatKey';


const apiUrl = process.env.REACT_APP_API_BASE_URL;


const FreeProgramCard = () => {
  const { randomData } = useContext(FreeContext);
  const navigate = useNavigate();
  const [racesData, setRacesData] = useState([]);

  // Function to generate random odds as a fraction (e.g., 5/1, 7/2, etc.)
  const generateRandomOdds = () => {
    const numerator = Math.floor(Math.random() * 10) + 1;
    const denominator = Math.floor(Math.random() * 10) + 1;
    return `${numerator}/${denominator}`;
  };

  // Function to shuffle an array randomly
  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  // Function to generate a random race with unique horse data, jockeys, trainers, owners, and tracks
  const generateRandomRace = () => {
    const shuffledHorses = shuffleArray(randomData.horses);
    const shuffledJockeys = shuffleArray(randomData.jockeys);
    const shuffledTrainers = shuffleArray(randomData.trainers);
    const shuffledOwners = shuffleArray(randomData.owners);
    const shuffledTracks = shuffleArray(randomData.tracks);

    const raceData = shuffledHorses.slice(0, 7).map((horse, index) => ({
      horseName: horse.horse_name,
      horseId: horse.id,
      jockeyName: shuffledJockeys.length > index ? shuffledJockeys[index].jockey_name : '',
      jockeyId: shuffledJockeys.length > index ? shuffledJockeys[index].id : null,
      trainerName: shuffledTrainers.length > index ? shuffledTrainers[index].trainer_name : '',
      trainerId: shuffledTrainers.length > index ? shuffledTrainers[index].id : null,
      ownerName: shuffledOwners.length > index ? shuffledOwners[index].owner_name : '',
      ownerId: shuffledOwners.length > index ? shuffledOwners[index].id : null,
      trackName: shuffledTracks.length > index ? shuffledTracks[index].track_name : '',
      trackId: shuffledTracks.length > index ? shuffledTracks[index].id : null,
      odds: generateRandomOdds(),
    }));

    return raceData;
  };

  // Function to start a race with selected horse data
  const handleStartRace = (raceData) => {
    navigate('/free-race', { state: { horseData: raceData } });
  };

  useEffect(() => {
    // Generate random races data when randomData changes
    const generatedRacesData = Array.from({ length: Math.floor(Math.random() *2) + 1 }, () =>
      generateRandomRace()
    );
    setRacesData(generatedRacesData);
  }, [randomData]);


  return (
  <div style={{ marginTop: '4%', marginLeft: '2%' }}>
    <div style={{ textAlign: 'center', marginBottom: '20px' }}>
      <h1>Free Program Card</h1>
      <p>
        Want to see more free races, create horses, place bets?{'  '}
        <button
          style={{
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            padding: '10px 15px',
            cursor: 'pointer',
          }}
          onClick={() => navigate('/register')}
        >
          Sign Up for a Free Account
        </button>
      </p>
    </div>
    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
      {racesData.map((race, raceIndex) => (
        <div key={raceIndex}>
          <div style={{ flex: 1 }}>
            <h2>Race {raceIndex + 1} Details:</h2>
            <button onClick={() => handleStartRace(race)}>Start Race</button>
            {race.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <th>Post</th>
                    <th>No.</th>
                    <th>
                      <FormatKey keyText="horse_name" />
                    </th>
                    <th>
                      <FormatKey keyText="jockey_name" />
                    </th>
                    <th>
                      <FormatKey keyText="trainer_name" />
                    </th>
                    <th>
                      <FormatKey keyText="owner_name" />
                    </th>
                    <th>Odds</th>
                  </tr>
                </thead>
                <tbody>
                  {race.map((horse, horseIndex) => (
                    <tr key={horseIndex}>
                      <td>{horseIndex + 1}</td>
                      <td>{horse.horseId}</td>
                      <td>{horse.horseName}</td>
                      <td>{horse.jockeyName}</td>
                      <td>{horse.trainerName}</td>
                      <td>{horse.ownerName}</td>
                      <td>{horse.odds}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div>No horse names available for Race {raceIndex + 1}.</div>
            )}
          </div>
        </div>
      ))}
    </div>
    <FreeRandomData count={20} />
  </div>
);
};

export default FreeProgramCard;