
import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation } from "react-router";
import "../Styles/FreeRace.css";
import FreeToteBoards from "./FreeToteBoards";
import { FreeContext } from "./FreeContext";
import { UnifiedPreloadProvider } from "../Modules/UnifiedPreloadContext";

function FreeRace() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const { race, setRace, selectedHorseIds, setSelectedHorseIds, selectedHorseNames, setSelectedHorseNames } =
    useContext(FreeContext);

  const [showTrack, setShowTrack] = useState(false);
  const [loading, setLoading] = useState(true);
  const [horseSpeeds, setHorseSpeeds] = useState([]);
  const [horseDurations, setHorseDurations] = useState([]);
  const [numHorses, setNumHorses] = useState(7);
  const raceTrackRef = useRef(null);

  useEffect(() => {
    const fetchRace = async () => {
      try {
        if (!id) {
          console.error("Invalid race ID");
          return;
        }
        const response = await fetch(`/api/current_races/${id}`);
        const data = await response.json();
        console.log("Fetched race data:", data);
        setRace(data);
        setNumHorses(data.num_horses);
      } catch (error) {
        console.error("Error fetching race data:", error);
      }
    };

    fetchRace();

    setTimeout(() => {
      setShowTrack(true);
    }, 2000);

    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, [id, setRace]);

  useEffect(() => {
    const initializeRaceAssets = () => {
      console.log("Initializing horse speeds and durations...");

      const initialSpeeds = Array.from({ length: numHorses }, () =>
        Math.random() * 129 + 503
      );
      const randomDurations = Array.from({ length: numHorses }, () =>
        (Math.random() * 30 + 60).toFixed(2)
      );

      console.log("Horse speeds:", initialSpeeds);
      console.log("Horse durations:", randomDurations);

      setHorseSpeeds(initialSpeeds);
      setHorseDurations(randomDurations);
      setLoading(false);
    };

    initializeRaceAssets();
  }, [numHorses]);

  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  const getRandomValue = (min, max) => {
    const range = max - min;
    const variation = range * 1.9;
    const randomOffset = Math.random() * variation - variation / 2.7;
    return (Math.random() * range + min + randomOffset).toFixed(2);
  };

  return (
    <UnifiedPreloadProvider>
      {({ startVideo, gateImage, trackVideo, horseImages }) => {
        // Debug logs for incoming assets
        console.log("Start Video:", startVideo);
        console.log("Gate Image:", gateImage);
        console.log("Track Video:", trackVideo);
        console.log("Horse Images:", horseImages);

        return (
          <div className="race-screens-container">
            <figure className="track-container" style={{ zIndex: 3 }}>
              {/* Start Video */}
              <video
                className={`track-left ${showTrack ? "fade-out" : ""}`}
                src={startVideo}
                autoPlay
                muted
                loop
                style={{
                  position: "fixed",
                  width: "100vw",
                  height: "100vh",
                  objectFit: "cover",
                }}
              />

              {/* Gate Image */}
              <img
                className={`track-left ${showTrack ? "fade-out" : ""}`}
                src={gateImage}
                alt="Start Gate"
                style={{
                  position: "fixed",
                  width: "100vw",
                  height: "100vh",
                  objectFit: "cover",
                }}
              />

              {/* Track Video */}
              {showTrack && (
                <video
                  className={`track-right ${showTrack ? "fade-in" : ""}`}
                  src={trackVideo}
                  autoPlay
                  muted
                  loop
                  style={{
                    position: "fixed",
                    width: "100vw",
                    height: "100vh",
                    objectFit: "cover",
                    zIndex: 2,
                  }}
                />
              )}
            </figure>

            <figure
              className="race-screen-race-track"
              ref={raceTrackRef}
              style={{ zIndex: 4 }}
            >
              {horseImages.slice(0, numHorses).map((image, index) => {
                return (
                  <figure
                    key={image.id || index}
                    className="horse"
                    style={{
                      animationDuration: `${horseDurations[index]}s`,
                      marginTop: `${52 + index * 2.5}vh`,
                      marginLeft: "-12vw",
                      position: "absolute",
                      zIndex: 5,
                    }}
                  >
                    <img
                      className="race-screen-horse-container"
                      src={image.url}
                      alt={image.image_name || `Horse ${index + 1}`}
                      style={{
                        width: "auto",
                        height: "22vh",
                      }}
                    />
                    <div
                      className="horse-number"
                      style={{
                        position: "absolute",
                        top: "40%",
                        left: "48%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "transparent",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      {index + 1}
                    </div>
                  </figure>
                );
              })}
            </figure>

            {/* Free Tote Boards */}
            <div
              className="freetoteboards-overlay"
              style={{ zIndex: 6, position: "relative" }}
            >
              <FreeToteBoards
                selectedHorses={horseImages.slice(0, numHorses).map((image, index) => ({
                  number: index + 1,
                  name: `Horse ${index + 1}`,
                }))}
              />
            </div>
          </div>
        );
      }}
    </UnifiedPreloadProvider>
  );
}

export default FreeRace;
