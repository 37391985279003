import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LazyLoadDropdown from '../Modules/LazyLoadDropdown'; // Correct import path

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const CreateHorseForm = () => {
  const [horseName, setHorseName] = useState('');
  const [breedId, setBreedId] = useState('');
  const [dob, setDob] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [sire, setSire] = useState('');
  const [dam, setDam] = useState('');
  const [breeder, setBreeder] = useState('');
  const [jockeyId, setJockeyId] = useState('');
  const [trainerId, setTrainerId] = useState('');
  const [ownerId, setOwnerId] = useState('');
  const [breeds, setBreeds] = useState([]);
  const [breedDescription, setBreedDescription] = useState('');
  const userId = localStorage.getItem('userId'); // Retrieve userId from local storage

  // Calculate the max date (today's date)
  const today = new Date();
  const maxDate = today.toISOString().split('T')[0];

  useEffect(() => {
    axios.get(`${apiUrl}/api/breeds`)
      .then(response => setBreeds(response.data))
      .catch(error => console.error('Error fetching breeds:', error));
  }, []);

  const handleBreedChange = (e) => {
    const selectedBreedId = e.target.value;
    setBreedId(selectedBreedId);

    const selectedBreed = breeds.find(breed => breed.breed_id === parseInt(selectedBreedId));
    if (selectedBreed) {
      setBreedDescription(selectedBreed.breed_description);
    } else {
      setBreedDescription('');
    }
  };

  const handleDobChange = (e) => {
    const dobValue = e.target.value;
    setDob(dobValue);
    const calculatedAge = calculateAge(dobValue);
    setAge(calculatedAge);
  };

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const difference = today - birthDate.getTime();
    const ageDate = new Date(difference);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const horseData = {
      horse_name: horseName,
      breed_id: breedId,
      dob,
      age,
      gender,
      sire,
      dam,
      breeder,
      jockey_id: jockeyId,
      trainer_id: trainerId,
      owner_id: ownerId,
      user_id: userId
    };
    console.log('horseData', horseData);
    axios.post(`${apiUrl}/api/horses`, horseData) // Fixed typo in POST URL
      .then(response => alert('Horse created successfully'))
      .catch(error => alert('Error creating horse'));
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Horse Name:</label>
        <input
          type="text"
          value={horseName}
          onChange={(e) => setHorseName(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Breed:</label>
        <select
          value={breedId}
          onChange={handleBreedChange}
          required
        >
          <option value="">Select Breed</option>
          {breeds.map(breed => (
            <option key={breed.breed_id} value={breed.breed_id}>
              {breed.breed_name} - {breed.breed_description}
            </option>
          ))}
        </select>
        {breedDescription && (
          <div>
            <p><strong>Breed Description:</strong> {breedDescription}</p>
          </div>
        )}
      </div>
      <div>
        <label>Date of Birth:</label>
        <input
          type="date"
          value={dob}
          onChange={handleDobChange}
          max={maxDate}
          required
        />
      </div>
      <div>
        <label>Age:</label>
        <input
          type="number"
          value={age}
          readOnly
        />
      </div>
      <div>
        <label>Gender:</label>
        <select
          value={gender}
          onChange={(e) => setGender(e.target.value)}
          required
        >
          <option value="">Select Gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
        </select>
      </div>
      <div>
        <label>Sire:</label>
        <input
          type="text"
          value={sire}
          onChange={(e) => setSire(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Dam:</label>
        <input
          type="text"
          value={dam}
          onChange={(e) => setDam(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Breeder:</label>
        <input
          type="text"
          value={breeder}
          onChange={(e) => setBreeder(e.target.value)}
          required
        />
      </div>
      {/* Corrected template literals in LazyLoadDropdown */}
      <LazyLoadDropdown
        apiEndpoint={`${apiUrl}/api/jockeys`}
        label="Jockey"
        value={jockeyId}
        onChange={(e) => setJockeyId(e.target.value)}
        displayProperty="jockey_name"
        idProperty="id"
      />
      <LazyLoadDropdown
        apiEndpoint={`${apiUrl}/api/trainers`}
        label="Trainer"
        value={trainerId}
        onChange={(e) => setTrainerId(e.target.value)}
        displayProperty="trainer_name"
        idProperty="id"
      />
      <LazyLoadDropdown
        apiEndpoint={`${apiUrl}/api/owners`}
        label="Owner"
        value={ownerId}
        onChange={(e) => setOwnerId(e.target.value)}
        displayProperty="owner_name"
        idProperty="id"
      />
      <button type="submit">Create Horse</button>
    </form>
  );
};

export default CreateHorseForm;
