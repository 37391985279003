
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const EnterRaceForm = ({ races, onHorseAdded }) => {
//   const [userHorses, setUserHorses] = useState([]);
//   const [selectedRace, setSelectedRace] = useState('');
//   const [selectedHorse, setSelectedHorse] = useState('');

//   useEffect(() => {
//     const userId = localStorage.getItem('userId');
//     axios.get(`${apiUrl}/api/horses`, { params: { user_id: userId } })
//       .then(response => {
//         console.log('Fetched user horses:', response.data);
//         setUserHorses(response.data);
//       })
//       .catch(error => console.error('Error fetching horses:', error));
//   }, []);

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log('Selected race:', selectedRace);
//     console.log('Selected horse:', selectedHorse);
//     addHorseToCurrentRace(selectedRace, selectedHorse);
//   };

//   const addHorseToCurrentRace = async (raceId, horseId) => {
//     try {
//       const updates = { horse_id1: horseId }; // Adjust based on which field you are updating
//       const response = await axios.patch(`${apiUrl}/api/horseracing/current_races/race_id/${raceId}`, updates);
//       console.log('Horse added to current race:', response.data);
//       onHorseAdded(response.data);
//     } catch (error) {
//       console.error('Error adding horse to current race:', error);
//       alert('Error adding horse to current race');
//     }
//   };

//   return (
//     <div>
//       <form onSubmit={handleSubmit}>
//         <div>
//           <label htmlFor="race">Select Race:</label>
//           <select
//             id="race"
//             value={selectedRace}
//             onChange={(e) => setSelectedRace(e.target.value)}
//             required
//           >
//             <option value="">--Select Race--</option>
//             {races.map((race) => (
//               <option key={`race-${race.id}`} value={race.id}>
//                 {race.race_name}
//               </option>
//             ))}
//           </select>
//         </div>
//         <div>
//           <label htmlFor="horse">Select Horse:</label>
//           <select
//             id="horse"
//             value={selectedHorse}
//             onChange={(e) => setSelectedHorse(e.target.value)}
//             required
//           >
//             <option value="">--Select Horse--</option>
//             {userHorses.map((horse) => (
//               <option key={`horse-${horse.id}`} value={horse.id}>
//                 {horse.horse_name}
//               </option>
//             ))}
//           </select>
//         </div>
//         <button type="submit">Enter Race</button>
//       </form>
//     </div>
//   );
// };

// export default EnterRaceForm;


import React, { useState, useEffect } from 'react';
import axios from 'axios';


const apiUrl = process.env.REACT_APP_API_BASE_URL;


const EnterRaceForm = ({ races = [], onHorseAdded }) => { // Default to an empty array for `races`
  const [userHorses, setUserHorses] = useState([]);
  const [selectedRace, setSelectedRace] = useState('');
  const [selectedHorse, setSelectedHorse] = useState('');

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    axios.get(`${apiUrl}/api/horses`, { params: { user_id: userId } })
      .then(response => {
        console.log('Fetched user horses:', response.data);
        setUserHorses(response.data);
      })
      .catch(error => console.error('Error fetching horses:', error));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Selected race:', selectedRace);
    console.log('Selected horse:', selectedHorse);
    addHorseToCurrentRace(selectedRace, selectedHorse);
  };

  const addHorseToCurrentRace = async (raceId, horseId) => {
    try {
      const updates = { horse_id1: horseId }; // Adjust based on which field you are updating
      const response = await axios.patch(`${apiUrl}/api/horseracing/current_races/race_id/${raceId}`, updates);
      console.log('Horse added to current race:', response.data);
      onHorseAdded(response.data);
    } catch (error) {
      console.error('Error adding horse to current race:', error);
      alert('Error adding horse to current race');
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="race">Select Race:</label>
          <select
            id="race"
            value={selectedRace}
            onChange={(e) => setSelectedRace(e.target.value)}
            required
          >
            <option value="">--Select Race--</option>
            {races.length > 0 ? (
              races.map((race) => (
                <option key={`race-${race.id}`} value={race.id}>
                  {race.race_name}
                </option>
              ))
            ) : (
              <option disabled>No races available</option>
            )}
          </select>
        </div>
        <div>
          <label htmlFor="horse">Select Horse:</label>
          <select
            id="horse"
            value={selectedHorse}
            onChange={(e) => setSelectedHorse(e.target.value)}
            required
          >
            <option value="">--Select Horse--</option>
            {userHorses.length > 0 ? (
              userHorses.map((horse) => (
                <option key={`horse-${horse.id}`} value={horse.id}>
                  {horse.horse_name}
                </option>
              ))
            ) : (
              <option disabled>No horses available</option>
            )}
          </select>
        </div>
        <button type="submit">Enter Race</button>
      </form>
    </div>
  );
};

export default EnterRaceForm;
