
import React, { useState, useEffect } from 'react';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_BASE_URL;

function AdminProfile() {
  const [columns, setColumns] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [formData, setFormData] = useState({});
  const [numColumns, setNumColumns] = useState(10);

  useEffect(() => {
    axios.get(' ${apiUrl}/api/users')
      .then(response => {
        // Get the column names from the first row of the table
        setColumns(Object.keys(response.data[0]));

        // Set the users data
        setUsers(response.data);
      })
      .catch(error => console.error(error));
  }, []);

  function handleRowClick(user) {
    setSelectedUser(user);
    setFormData(user);
  }

  function handleInputChange(event) {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  }

  function handleFormSubmit(event) {
    event.preventDefault();

    // Update the user data on the server
    axios.put(`${apiUrl}/api/users/${selectedUser.id}`, formData)
      .then(response => {
        // Update the user data in the state
        const updatedUsers = [...users];
        const index = updatedUsers.findIndex(user => user.id === response.data.id);
        updatedUsers[index] = response.data;
        setUsers(updatedUsers);

        // Reset the selected user and form data
        setSelectedUser(null);
        setFormData({});
      })
      .catch(error => console.error(error));
  }

//   function handleCreateSubmit(event) {
//     event.preventDefault();

//     // Create the new user data on the server
//     axios.post(' ${apiUrl}/api/users', formData)
//       .then(response => {
//         // Add the new user data to the state
//         const updatedUsers = [...users, response.data];
//         setUsers(updatedUsers);

//         // Reset the form data
//         setFormData({});
//       })
//       .catch(error => console.error(error));
//   }


  function handleDeleteClick(user) {
    // Delete the user data on the server
    axios.delete(`${apiUrl}/api/users/${user.id}`)
      .then(response => {
        // Remove the user data from the state
        const updatedUsers = users.filter(u => u.id !== user.id);
        setUsers(updatedUsers);

        // If the deleted user was selected, clear the form data and selection
        if (selectedUser && selectedUser.id === user.id) {
          setSelectedUser(null);
          setFormData({});
        }
      })
      .catch(error => console.error(error));
  }

  function handleClearForm() {
    setSelectedUser(null);
    setFormData({});
  }

  // function handleNumColumnsChange(event) {
  //   const value = parseInt(event.target.value);
  //   setNumColumns(value);
  //   if (value > 0) {
  //     setFormData((prevFormData) => {
  //       const formDataSubset = {};
  //       columns.slice(0, value).forEach((column) => {
  //         formDataSubset[column] = prevFormData[column];
  //       });
  //       return formDataSubset;
  //     });
  //   }
  // }


  function handleNumColumnsChange(event) {
    const value = parseInt(event.target.value);
    setNumColumns(value);
    if (value > 0) {
      setFormData((prevFormData) => {
        const formDataSubset = {};
        columns.slice(6, value).forEach((column) => {
          formDataSubset[column] = prevFormData[column];
        });
        return formDataSubset;
      });
    } else {
      setFormData({});
    }
  }
  

  return (
    <div>
      <h2>User Table</h2>
      <div>
        <label>Number of columns to display:</label>
        <select value={numColumns} onChange={handleNumColumnsChange}>
          <option value="10">10</option> {/* Set default value */}
          <option value="0">All</option>
      {columns.map((column, index) => (
            <option key={index} value={index + 1}>{index + 1}</option>
  ))}
</select>

      </div>
      <table>
        <thead>
          <tr>
            {numColumns > 0 ? columns.slice(0, numColumns).map(column => <th key={column}>{column}</th>) : columns.map(column => <th key={column}>{column}</th>)}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr>
            {numColumns > 0 ? Object.entries(user).slice(0, numColumns).map(([key, value]) => <td key={key}>{value}</td>) : Object.entries(user).map(([key, value]) => <td key={key}>{value}</td>)}

            <td>
            <button onClick={() => handleRowClick(user)}>Edit</button>
            <button onClick={() => handleDeleteClick(user)}>Delete</button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
  {selectedUser &&
    <div>
      <h3>Edit User {selectedUser.id}</h3>
      <form onSubmit={handleFormSubmit}>
        {Object.entries(formData).map(([key, value]) => (
          <div key={key}>
            <label>{key}:</label>
            <input type="text" name={key} value={value} onChange={handleInputChange} />
          </div>
        ))}
        <button type="submit">Update</button>
        <button type="button" onClick={handleClearForm}>Cancel</button>
      </form>
    </div>
  }
{/* <form onSubmit={handleCreateSubmit}>
<h3>Add User</h3>
{columns.map(column => (
<div key={column}>
<label>
{column}:
<input type="text" name={column} value={formData[column] || ''} onChange={handleInputChange} />
</label>
</div>
))}
<div>
<button type="submit">Create User</button>
</div>
</form> */}
</div>
);
}

export default AdminProfile;

