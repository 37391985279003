import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import '../Styles/Navbar.css';
// import Hamburger from './Hamburger';
// import { useAuth } from "../Modules/Auth/AuthContext"; // Import the useAuth hook



const apiUrl = process.env.REACT_APP_API_BASE_URL;


const Navbar = () => {
  // const { isLoggedIn } = useAuth(); // Access the isLoggedIn state

  return (
    <>
    <nav className="navbar" >
      <div className="navbar-wrapper" style={{paddingTop:'1vh',paddingBottom:'1vh', marginBottom:'1vh'}}>      
      <ul> <NavLink to="/home" active="active" style={{textDecoration: 'none', color:'black',marginRight:'1vh'}}>Home</NavLink> </ul>
     
      <ul className='menu-list dropdown'>Free Races 
      <ul className='dropdown'>    
       <li> <NavLink to="/free-program-card" active="active"> Program Card</NavLink> </li>
       <li> <NavLink to="/free-leader-board" active="active"> Leader Board</NavLink> </li>
       <li> <NavLink to="/free-race" active="active">Race Preview </NavLink> </li>
       </ul>          
       </ul>

       <ul className='menu-list dropdown'>PROGRAMS <ul className='dropdown'>    
       <li> <NavLink to="/program-card" active="active"> Program Card</NavLink> </li>
       <li> <NavLink to="/track-race-dates" active="active"> Race Dates</NavLink> </li>
       <li> <NavLink to="/leader-board" active="active">Leader Board</NavLink> </li>
       <li> <NavLink to="/free-race" active="active">Race Preview </NavLink> </li>
       </ul>          
       </ul>
          <ul className='menu-list dropdown'>STATS & PROFILES 
          <ul className='dropdown'>            
            <li> <NavLink to="/horse" active="active">Horse</NavLink> </li>
            <li > <NavLink to="/jockey" active="active">Jockey</NavLink> </li>
            <li> <NavLink to="/trainer" active="active">Trainer</NavLink> </li>
            <li> <NavLink to="/race-track" active="active">Track</NavLink> </li>
            <li> <NavLink to="/races" active="active">Races</NavLink> </li>
            {/* <li> <NavLink to="/owner" active="active">Owners</NavLink> </li> */}
            {/* OWNERS SHOWS USER PASSWORDS AND NEEDS TO BE CHANGED */}

          </ul>
        </ul>           
       <ul className='menu-list dropdown'>RACING 
       <ul className='dropdown'>
       <li> <NavLink to="/current-race-screens" active="active">Race Screens</NavLink> </li>
       <li> <NavLink to="/race-results" active="active">Race Results</NavLink> </li>        
       <li> <NavLink to="/gear" active="active">Race Gear</NavLink> </li>
       
        </ul>
        </ul>
       <ul className='menu-list dropdown'>BETTING 
       <ul className='dropdown'>
       <li> <NavLink to="/bets" active="active">Bets</NavLink> </li> 
 {/* working on bets */}
        <li> <NavLink to="/race-details" active="active">Race Details</NavLink> </li>
        <li> <NavLink to="/horse-race-predictor" active="active">Horse Race Predictor</NavLink> </li>

        <li> <NavLink to="/bet-payouts" active="active">Payouts</NavLink> </li>
        <li> <NavLink to="/bet-odds" active="active">ODDS</NavLink> </li>
        </ul>
        </ul>
        <ul className='menu-list dropdown'>AUCTIONS 
        <ul className='dropdown'>
        <li> <NavLink to="/auction" active="active">Auction</NavLink> </li>
        <li> <NavLink to="/dates" active="active">Dates</NavLink> </li>
        <li> <NavLink to="/breeds" active="active">Breeds</NavLink> </li>
        <li> <NavLink to="/public" active="active">Public</NavLink> </li>
        <li> <NavLink to="/private" active="active">Private</NavLink> </li>
        <li> <NavLink to="/bidding" active="active">Bidding</NavLink> </li>
        <li> <NavLink to="/current-highest-bid" active="active">High Bid</NavLink> </li>
        <li> <NavLink to="/auction-results" active="active">Results</NavLink> </li>
        <li> <NavLink to="/facilities" active="active">Facilities</NavLink> </li>
        <li> <NavLink to="/staff" active="active">Staff</NavLink> </li>
        <li> <NavLink to="/sales-contract" active="active">Contract</NavLink> </li>
        <li> <NavLink to="/transportation" active="active">Transportation</NavLink> </li>
        </ul>
          </ul>
          <ul className='menu-list dropdown'>STABLES      
        <ul className='dropdown'>
        <li> <NavLink to="/breeding" active="active">Breeding</NavLink> </li>
        <li> <NavLink to="/horse-training" active="active">Horse Training</NavLink> </li>
        <li> <NavLink to="/medical" active="active">Medical</NavLink> </li>
        <li> <NavLink to="/shoeing" active="active">Shoeing</NavLink> </li>
        </ul>
        </ul>     
          <ul className='menu-list dropdown'>INFORMATION
        <ul className='dropdown'>
        <li> <NavLink to="/instructions" active="active">Instructions</NavLink> </li>
        <li> <NavLink to="/news" active="active">Racing News</NavLink> </li>
        <li> <NavLink to="/tutorial" active="active">Tutorials</NavLink> </li>
        <li> <NavLink to="/learn-about-racing" active="active">Learn Racing</NavLink> </li>
        <li> <NavLink to="/bet-types" active="active">Bet Types</NavLink> </li>
        <li> <NavLink to="/faqs" active="active">FAQs</NavLink> </li>
        </ul>
        </ul>           
        <ul className='menu-list dropdown'style={{marginRight:'20px'}}>DASHBOARD      
       <ul className='dropdown'>
        <li> <NavLink to="/register" active="active">Register</NavLink> </li>                
        <li> <NavLink to="/login" active="active">Log In</NavLink> </li>
        <li> <NavLink to="/logout" active="active">Log Out</NavLink> </li>    
        <li> <NavLink to="/auth" active="active">Temp Auth Page</NavLink> </li>
        <li> <NavLink to="/create-horse" active="active">Create Horse</NavLink> </li>    
        <li> <NavLink to="/enter-race" active="active">Enter Race</NavLink> </li>    
        <li> <NavLink to="/user-dashboard" active="active">User Dashboard</NavLink> </li>        

        {/* {isLoggedIn && (    */}
        <li> <NavLink to="/sensi-pass-register" active="active"> Register SensiPass </NavLink> </li>
         {/* )} */}
        <li> <NavLink to="/profile" active="active">User Profile</NavLink> </li>  
        {/* <li><NavLink to="/data-consumer" active="active">Consumer</NavLink></li> */}
        {/* <li><NavLink to="/data-provider" active="active">Provider</NavLink></li> */}
        {/* <li> <NavLink to="/leaderboard" active="active">Leader Board</NavLink> </li> */}
        {/* 
        <li> <NavLink to="/admin-profile" active="active">Admin Profile</NavLink> </li> */}
        {/* <li> <NavLink to="/showDB" active="active">ShowDB</NavLink></li> */}
        {/* <li> <NavLink to="/add-columns" active="active">Add Column</NavLink> </li> */}
        {/* <li> <NavLink to="/insert-columns" active="active">Insert Columns</NavLink> </li> */}
        {/* <li> <NavLink to="/move-current-to-past" active="active">Move Currenet To Past</NavLink> </li> */}
        {/* <li> <NavLink to="/app-test" active="active">APP-TEST</NavLink></li> */}
         {/*  <li> <NavLink to="/free-random-data" active="active">Free Random Data</NavLink></li>
        <li> <NavLink to="/free-tote-board" active="active">Free Tote Boards</NavLink></li>
        <li> <NavLink to="/free-race" active="active">Free Race</NavLink></li> */}
        {/* <li> <NavLink to="/morning-line-odds" active="active">Morning Line</NavLink></li> */}

        {/* <li> <NavLink to="/data-generator" active="active">Data Generator</NavLink></li> */}

        {/* <li> <NavLink to="/run-scan-insert" active="active">Scan Static Pics</NavLink></li>        */}
        <li> <NavLink to="/endpoint-test" active="active">EndPoint-TEST</NavLink></li>
           </ul>
         </ul>
         {/* <Hamburger/> */}
       
      </div>   
      </nav>

      <div style={{ padding: '1vh', marginBottom:'1vh' }}>
          <Outlet />
      </div>
    </>
  );
};

export default Navbar;