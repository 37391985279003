import React, { useState, useEffect } from 'react';
import axios from 'axios';


const apiUrl = process.env.REACT_APP_API_BASE_URL;


function BetTypes() {
  const [betTypes, setBetTypes] = useState([]);

  useEffect(() => {
    axios.get(' ${apiUrl}/api/bet_types')
      .then(response => {
        setBetTypes(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  return (
    <div>
      <h2>Bet Types</h2>
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {betTypes.map(betType => (
            <tr key={betType.id}>
              <td>{betType.bet_type}</td>
              <td>{betType.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* <div>
        <label htmlFor="betType">Bet Type:</label>
        <input type="text" id="betType" name="betType" />
      </div> */}
    </div>
  );
}

export default BetTypes;