import React from "react";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

function QuoteForm() {
  return (
    <div style={{ width: "auto", marginLeft: "1vw",  fontSize: "16px" }}>
      <div style={{fontSize: "16px" }}>
        <h1 style={{marginLeft:'10vw',textAlign:'left', lineHeight:'3pt'}}>MHIS - LIGHTNING QUOTE</h1>
        <br/>

        <div>
          <p  style={{marginLeft:'10vw',textAlign:'left', lineHeight:'3pt'}}>Instructions: Complete section QUOTE ONLY for an instant QUOTE.</p>
            <p  style={{marginLeft:'10vw',textAlign:'left', lineHeight:'3pt'}}>
            Plus complete section FINISH FOR BINDER REQUEST for BINDER or
            POLICY.
          </p>
            <p style={{marginLeft:'10vw',textAlign:'left', lineHeight:'3pt'}}>Fax to: 714-628-0923 visit us on the web at www.mhis.info</p>
        </div>
      </div>
     

      <div
        style={{
          display: "inline-block",
          marginTop: "-0px",
          marginLeft: "0 auto"
        }}
      >  
        <form
          style={{
            display: "inline-block",
            marginLeft: "10vw",
            marginRight: "10vw",
            maxWidth: "94vw"
          , fontSize: "16px" }}
          action="/send-email" // Set the action to a route that handles email sending
          method="POST" 
        >
          <div style={{textAlign:'center', marginTop:'-8vh'}}> <h2>QUOTE ONLY</h2>
            <label  style={{textAlign:'left'}}>Name:</label>
            <input
              style={{ display: "inline-block", width: "47%" , fontSize: "16px" }}
              type="text"
              name="firstName"
              placeholder="First Name"
            />
            <input
              style={{
                display: "inline-block",
                width: "47%",
                marginLeft: "5px"
              , fontSize: "16px" }}
              type="text"
              name="lastName"
              placeholder="Last Name"
            />
          </div>
          <br />

          <div>
            <label
              style={{
                display: "inline-block",
                width: "8%",
                marginRight: "2%"
              , fontSize: "16px" }}
            >
              Phone:
            </label>
            <input
              style={{
                display: "inline-block",
                width: "25%",
                marginRight: "2%"
              , fontSize: "16px" }}
              type="tel"
              name="phone"
              placeholder="Phone Number"
            />
            <label
              style={{
                display: "inline-block",
                width: "8%",
                marginRight: "2%", fontSize: "16px" }}
            >
              Email:
            </label>
            <input
             style={{
              display: "inline-block",
              width: "47%",
              marginRight: "2%", fontSize: "16px" }}
              type="email"
              name="email"
              placeholder="Email Address"
            />
          </div>
          <br />

          <div>
            <label
              style={{
                display: "inline-block",
                width: "18%",
                marginRight: "1%"
              , fontSize: "16px" }}
            >
              Property Zip Code:
            </label>
            <input
              style={{
                display: "inline-block",
                width: "8%",
                marginRight: "10%"
              , fontSize: "16px" }}
              type="text"
              name="propertyZip"
              placeholder="Zip"
            />

            <label
              style={{
                display: "inline-block",
                width: "24%",
                marginRight: "2%"
              , fontSize: "16px" }}
            >
              Name 1. Date of Birth
            </label>
            <input
              style={{
                display: "inline-block",
                width: "12%",
                marginRight: "2%"
              , fontSize: "16px" , fontSize: "16px" }}
              type="text"
              name="name1dob"
              placeholder="DOB"
            />
          </div>
          <br />

          <label
            style={{
              display: "inline-block",
              width: "12%",
              marginRight: "1%"
            , fontSize: "16px" }}
          >
            Home Year:
          </label>
          <input
            style={{
              display: "inline-block",
              width: "6%",
              marginRight: "1%", fontSize: "16px"
            }}
            type="text"
            name="homeYear"
            placeholder="Year"
          />

          <label
            style={{
              display: "inline-block",
              width: "6%",
              marginRight: "2%", fontSize: "16px" 
            }}
          >
            Length:
          </label>
          <input
            style={{
              display: "inline-block",
              width: "4%",
              marginRight: "2%", fontSize: "16px" 
            }}
            type="text"
            name="length"
            placeholder="Ft."
          />

          <label
            style={{
              display: "inline-block",
              width: "6%",
              marginRight: "2%", fontSize: "16px" 
            }}
          >
            Width:
          </label>
          <input
            style={{
              display: "inline-block",
              width: "4%",
              marginRight: "2%", fontSize: "16px" 
            }}
            type="text"
            name="width"
            placeholder="Ft."
          />

          <label
            style={{
              display: "inline-block",
              width: "8%",
              marginRight: "-1%", fontSize: "16px" 
            }}
          >
            In Park:
          </label>
          <input
            style={{
              display: "inline-block",
              width: "2%",
              marginRight: "1%", fontSize: "16px" 
            }}
            type="checkbox"
            name="inPark"
          />

          <label
            style={{
              display: "inline-block",
              width: "13%",
              marginRight: "-1%", fontSize: "16px" 
            }}
          >
            Private Land:
          </label>
          <input
            style={{
              display: "inline-block",
              width: "2%",
              marginRight: "2%", fontSize: "16px" 
            }}
            type="checkbox"
            name="privateLand"
          />
<br/>
          <label style={{
              display: "inline-block",
              width: "18%",
              marginRight: "-1%", fontSize: "16px" 
            }} >Property Address:</label>
          <br />
          <input style={{
              display: "inline-block",
              width: "32%",
              marginRight: "-1%", fontSize: "16px" 
            }} 
            type="text" name="street" placeholder="Street" />
          <input style={{
              display: "inline-block",
              width: "18%",
              marginRight: "-1%", fontSize: "16px" 
            }} type="text" name="space" placeholder="Space" />
          <input style={{
              display: "inline-block",
              width: "28%",
              marginRight: "-1%", fontSize: "16px" 
            }} type="text" name="city" placeholder="City" />
          <input style={{
              display: "inline-block",
              width: "13%",
              marginRight: "-1%", fontSize: "16px" 
            }} type="text" name="state" placeholder="State" />
          <br />


          <label style={{
              display: "inline-block",
              width: "34%",
              marginRight: "1%", fontSize: "16px" 
            }}>Purchase Price or amount Insured:</label>
          <input style={{
              display: "inline-block",
              width: "12%",
              marginRight: "1%", fontSize: "16px" 
            }}
            type="text"
            name="purchasePrice"
            placeholder="$ Amount"
          />
          <label style={{
              display: "inline-block",
              width: "14%",
              marginRight: "1%", fontSize: "16px" 
            }}>Effective Date:</label>
          <input style={{
              display: "inline-block",
              width: "13%",
              marginRight: "-1%", fontSize: "16px" 
            }} type="date" name="effectiveDate" />
          <br />




          <h3 style={{
              display: "inline-block",
              width: "2%",
              marginRight: "6%", fontSize: "16px" 
            }}>Garage</h3>
          <input  style={{
              display: "inline-block",
              width: "2%",
              marginRight: "1%", fontSize: "16px" 
            }} type="checkbox" name="garageAttached" />
          <label style={{
              display: "inline-block",
              width: "4%",
              marginRight: "1%", fontSize: "16px" 
            }}>Attached:</label>
         
          <input style={{
              display: "inline-block",
              width: "12%",
              marginRight: "-4%", fontSize: "16px" 
            }} type="checkbox" name="garageDetached" />
             <label style={{
              display: "inline-block",
              width: "10%",
              marginRight: "1%", fontSize: "16px" 
            }}>Detached:</label>
          <label style={{
              display: "inline-block",
              width: "18%",
              marginRight: "1%", fontSize: "16px" 
            }} >Garage cash value:</label>
          <input style={{
              display: "inline-block",
              width: "12%",
              marginRight: "1%", fontSize: "16px" 
            }}
            type="text"
            name="garageCashValue"
            placeholder="Cash Value"
          />
<label style={{
              display: "inline-block",
              width: "%",
              marginRight: "1%", fontSize: "16px" 
            }} >Claims?:</label>
          <input style={{
              display: "inline-block",
              width: "8%",
              marginRight: "-1%", fontSize: "16px" 
            }}
            type="text"
            name="claims"
            placeholder="Claims"
          />
          <br />





          <label style={{
              display: "inline-block",
              width: "16%",
              marginRight: "-3%", fontSize: "16px" 
            }}>Owner occupied:</label>
          <input style={{
              display: "inline-block",
              width: "8%",
              marginRight: "-1%", fontSize: "16px" 
            }} type="checkbox" name="ownerOccupied" />
          <label style={{
              display: "inline-block",
              width: "16%",
              marginRight: "-4%", fontSize: "16px" 
            }} >Vacant or Spec.:</label>
          <input style={{
              display: "inline-block",
              width: "8%",
              marginRight: "-1%", fontSize: "16px" 
            }} type="checkbox" name="vacantOrSpec" />
          <label style={{
              display: "inline-block",
              width: "8%",
              marginRight: "-4%", fontSize: "16px" 
            }}>Rental:</label>
          <input style={{
              display: "inline-block",
              width: "8%",
              marginRight: "-2%", fontSize: "16px" 
            }} type="checkbox" name="rental" />
          <label style={{
              display: "inline-block",
              width: "8%",
              marginRight: "-2%", fontSize: "16px" 
            }}>Seasonal:</label>
          <input style={{
              display: "inline-block",
              width: "8%",
              marginRight: "-1%",
            fontSize: "16px" }} type="checkbox" name="seasonal" />
          <label style={{
              display: "inline-block",
              width: "23%",
              marginRight: "-4%",
            fontSize: "16px" }}>Course of Construction:</label>
          <input style={{
              display: "inline-block",
              width: "8%",
              marginRight: "-8%",
            fontSize: "16px" }} type="checkbox" name="courseOfConstruction" />
          <br />





          <label style={{
              display: "inline-block",
              width: "26%",
              marginRight: "-1%",
            fontSize: "16px" }}>Add Earthquake Coverage:</label>
          <input style={{
              display: "inline-block",
              width: "2%",
              marginRight: "1%",
            fontSize: "16px" }} type="checkbox" name="earthquakeCoverage" />
          <label style={{
              display: "inline-block",
              width: "22%",
              marginRight: "-2%",
            fontSize: "16px" }}>Add Flood Coverage:</label>
          <input style={{
              display: "inline-block",
              width: "2%",
              marginRight: "1%",
            fontSize: "16px" }} type="checkbox" name="floodCoverage" />
          <label style={{
              display: "inline-block",
              width: "24%",
              marginRight: "-4%",
            fontSize: "16px" }}>Additional Coverage:</label>
          <input style={{
              display: "inline-block",
              width: "24%",
              marginRight: "1%",
            fontSize: "16px" }}
            type="text"
            name="additionalCoverage"
            placeholder="Additional Coverage"
          />
            <button type="submit">Submit</button>
          <br />

        </form>
      </div>

      <h3  style={{marginLeft:'10vw'}}>Finish for Binder Request</h3>
      <form style={{ maxWidth: "74vw" }}>{/* The rest the form */}</form>
      <h4 style={{textAlign:'right',marginRight:'23.9vw'}}>Make Checks Payable to:</h4>
    <div style={{marginLeft:'60vw'}}>
      <p  style={{lineHeight:'1pt'}} >Manufactured Housing Insurance Services</p>
       <p  style={{lineHeight:'1pt'}} >1500 East Katella Avenue, Suite One</p>
       <p  style={{lineHeight:'1pt'}} >Orange, California 92867</p>
       <p  style={{lineHeight:'1pt'}} >714-628-1000 Fax 714-628-0923</p>
       <p  style={{lineHeight:'1pt'}} >Toll Free 866-644-7111 Fax 866-628-1033</p>
       <p  style={{lineHeight:'1pt'}} >Email customerservice@mhis.info</p>
       <p  style={{lineHeight:'1pt'}} >CDI lic. # 0D80824</p>
       <br/>
    </div>
    </div>
  );
}

export default QuoteForm;




// List all tracks

// import React, { useState, useEffect } from "react";
// import axios from "axios";

// const ProgramCard = () => {
//   const [tracks, setTracks] = useState([]); // Array of tracks
//   const [selectedLetter, setSelectedLetter] = useState(""); // Selected letter
//   const [numRows, setNumRows] = useState(5); // Number of rows to display

//   useEffect(() => {
//     // Fetch tracks data from the server when selectedLetter or numRows changes
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${apiUrl}/api/tracks`, {
//           params: {
//             _limit: numRows,
//             _sort: "track_name",
//             location_like: selectedLetter,
//             _distinct: "location,track_name,daily_races",
//           },
//         });

//         setTracks(response.data); // Update tracks state with fetched data
//       } catch (error) {
//         console.log(error);
//       }
//     };

//     if (selectedLetter) {
//       fetchData(); // Fetch data only if selectedLetter is not empty
//     } else {
//       setTracks([]); // Clear tracks state if selectedLetter is empty
//     }
//   }, [selectedLetter, numRows]); // Run effect when selectedLetter or numRows change

//   // Handle letter click event
//   const handleLetterClick = (letter) => {
//     setSelectedLetter(letter); // Update selectedLetter state
//   };

//   // Handle number of rows change event
//   const handleNumRowsChange = (event) => {
//     setNumRows(Number(event.target.value)); // Update numRows state
//   };

//   return (
//     <div>
//       <h2>Race Tracks by City</h2>
//       <div>
//         {/* Number of Rows Selector */}
//         <label htmlFor="numRows">Number of Tracks:</label>
//         <select
//           id="numRows"
//           name="numRows"
//           value={numRows}
//           onChange={handleNumRowsChange}
//           style={{ width: "12vw" }}
//         >
//           <option value={5}>5</option>
//           <option value={10}>10</option>
//           <option value={25}>25</option>
//           <option value={50}>50</option>
//         </select>
//       </div>

//       <div>
//         {/* Alphabet Letter Buttons */}
//         {Array.from("ABCDEFGHIJKLMNOPQRSTUVWXYZ").map((letter) => (
//           <button
//             key={letter}
//             onClick={() => handleLetterClick(letter)}
//             className={letter === selectedLetter ? "clicked" : ""}
//             disabled={letter === selectedLetter}
//           >
//             {letter}
//           </button>
//         ))}
//       </div>

//       <div>
//         {/* Display Tracks */}
//         {tracks.length > 0 ? (
//           <ul>
//             {tracks.map((track) => (
//               <li key={track.id}>{track.track_name}</li>
//             ))}
//           </ul>
//         ) : (
//           <p>
//             {selectedLetter
//               ? `Looking for tracks with locations that begin with the letter: '${selectedLetter}'`
//               : "Select a letter to view tracks"}
//           </p>
//         )}
//       </div>
//     </div>
//   );
// };

// export default ProgramCard;

// // COMPONENT IN THE WORKS TO DUPLICATE A SCHEMA TEMPLATE TO CREATE NEW SCHEMA

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// function ShowDB() {
//   const [schemaData, setSchemaData] = useState({ schemas: [], tables: [] });
//   const [selectedSchema, setSelectedSchema] = useState(null);
//   const [targetSchema, setTargetSchema] = useState('');
//   const [selectedTables, setSelectedTables] = useState([]);
//   const [fields, setFields] = useState([]);
//   const [numColumns, setNumColumns] = useState(1);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     // Fetch schemas and tables
//     axios.get(' ${apiUrl}/api/db/schemas-and-tables')
//       .then(response => {
//         console.log('Schema and Table Response:', response.data);
//         setSchemaData(response.data);
//         console.log('Current state:', schemaData);
//       })
//       .catch(error => {
//         console.error('Error fetching schemas and tables:', error);
//         setError('Error fetching schemas and tables. Please try again.');
//       });
//   }, []);

//   useEffect(() => {
//     // Fetch fields for the selected tables
//     if (selectedTables.length > 0) {
//       const fetchFields = async () => {
//         try {
//           const fieldsData = await Promise.all(selectedTables.map(table => (
//             axios.get(`${apiUrl}/api/db/${selectedSchema}/${table}/fields`)
//               .then(response => response.data)
//               .catch(error => {
//                 console.error('Error fetching fields:', error);
//                 return null;
//               })
//           )));

//           setFields(fieldsData.filter(Boolean)); // Filter out null responses
//         } catch (error) {
//           console.error('Error fetching fields:', error);
//           setError('Error fetching fields. Please try again.');
//         }
//       };

//       fetchFields();
//     } else {
//       setFields([]);
//     }
//   }, [selectedSchema, selectedTables]);

//   async function handleDuplicateSchema() {
//     setError(null); // Clear previous errors
//     if (selectedSchema && targetSchema) {
//       try {
//         // Dump the structure of the selected schema
//         const dumpResponse = await axios.post(`${apiUrl}/api/db/${selectedSchema}/dump-schema`, {
//           database: 'your_database', // Replace with your actual database name
//         });

//         // Create the target schema
//         await axios.post(`${apiUrl}/api/db/create-schema`, {
//           database: 'your_database', // Replace with your actual database name
//           schema: targetSchema,
//         });

//         // Apply the dumped structure to the target schema
//         await axios.post(`${apiUrl}/api/db/${targetSchema}/apply-dump`, {
//           database: 'your_database', // Replace with your actual database name
//           dump: dumpResponse.data,
//         });

//         // Optionally, you can refresh the schema and tables list
//         // For example, you can fetch the updated schema and tables list using the first useEffect
//         setTargetSchema(''); // Clear the target schema input after duplication
//         console.log('Schema duplicated successfully:', selectedSchema, 'to', targetSchema);

//         // Fetch schemas and tables after schema duplication
//         const refreshedResponse = await axios.get(' ${apiUrl}/api/db/schemas-and-tables');
//         setSchemaData(refreshedResponse.data);
//         console.log('Refreshed state:', refreshedResponse.data);
//       } catch (error) {
//         console.error('Error duplicating schema:', error);
//         setError('Error duplicating schema. Please check your input and try again.');
//       }
//     } else {
//       setError('Please select a source schema and enter a target schema.');
//     }
//   }

//   console.log('Render state:', schemaData, selectedSchema, targetSchema, selectedTables, fields);

//   return (
//     <div>
//       {error && <p style={{ color: 'red' }}>{error}</p>}

//       <div>
//         <label htmlFor="schemas">Select Source Schema:</label>
//         <select
//           id="schemas"
//           name="schemas"
//           value={selectedSchema || ''}
//           onChange={(event) => setSelectedSchema(event.target.value || null)}
//         >
//           <option value="" disabled>Select a Schema</option>
//           {schemaData.schemas.map(schema => (
//             <option key={schema} value={schema}>{schema}</option>
//           ))}
//         </select>
//       </div>

//       <div>
//         <label htmlFor="targetSchema">Select or Enter Target Schema:</label>
//         <select
//           id="targetSchema"
//           name="targetSchema"
//           value={targetSchema}
//           onChange={(event) => setTargetSchema(event.target.value)}
//         >
//           <option value="" disabled>Select a Schema</option>
//           {schemaData.schemas.map(schema => (
//             <option key={schema} value={schema}>{schema}</option>
//           ))}
//         </select>
//         <input
//           type="text"
//           placeholder="Or enter new schema name"
//           value={targetSchema}
//           onChange={(event) => setTargetSchema(event.target.value)}
//         />
//       </div>

//       <div>
//         <button onClick={handleDuplicateSchema}>Duplicate Schema</button>
//       </div>

//       {/* Display tables for the selected schema */}
//       <div>
//         <label>Tables in Selected Schema:</label>
//         {schemaData.tables ? (
//           schemaData.tables.length > 0 ? (
//             <ul>
//               {schemaData.tables.map(table => (
//                 <li key={table}>{table}</li>
//               ))}
//             </ul>
//           ) : (
//             <p>No tables found in the selected schema.</p>
//           )
//         ) : (
//           <p>Loading tables...</p>
//         )}
//       </div>

//       {/* Display fields for the selected tables */}
//       <div>
//         <label>Fields in Selected Tables:</label>
//         {fields ? (
//           fields.length > 0 ? (
//             <ul>
//               {fields.map((field, index) => (
//                 <li key={index}>{field}</li>
//               ))}
//             </ul>
//           ) : (
//             <p>No fields found in the selected tables.</p>
//           )
//         ) : (
//           <p>Loading fields...</p>
//         )}
//       </div>

//       {/* Rest of your existing UI code */}
//     </div>
//   );
// }

// export default ShowDB;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// function ShowDB() {
//   const [schemas, setSchemas] = useState([]); // State for storing schemas
//   const [tables, setTables] = useState([]);
//   const [selectedTables, setSelectedTables] = useState([]);
//   const [fields, setFields] = useState([]);
//   const [numColumns, setNumColumns] = useState(1);
//   const [selectedSchema, setSelectedSchema] = useState(''); // State for selected schema

//   // Fetch schemas on component mount
//   useEffect(() => {
//     axios.get(' ${apiUrl}/api/schemas')
//       .then(response => setSchemas(response.data))
//       .catch(error => console.error(error));
//   }, []);

//   // Fetch tables when selected schema changes
//   useEffect(() => {
//     if (selectedSchema) {
//       axios.get(`${apiUrl}/api/tables?schema=${selectedSchema}`)
//         .then(response => setTables(response.data))
//         .catch(error => console.error(error));
//     } else {
//       setTables([]);
//     }
//   }, [selectedSchema]);

//   // Fetch fields when tables are selected
//   useEffect(() => {
//     if (selectedTables.length > 0) {
//       const fetchFields = async () => {
//         const fieldsData = await Promise.all(selectedTables.map(table => (
//           axios.get(`${apiUrl}/api/${table}/fields`)
//             .then(response => response.data)
//             .catch(error => console.error(error))
//         )));
//         setFields(fieldsData);
//       };
//       fetchFields();
//     } else {
//       setFields([]);
//     }
//   }, [selectedTables]);

//   // Handle schema selection change
//   const handleSchemaChange = (event) => {
//     setSelectedSchema(event.target.value);
//   };

//   // Handle table click to toggle selection
//   const handleTableClick = (table) => {
//     if (selectedTables.includes(table)) {
//       setSelectedTables(selectedTables.filter(t => t !== table));
//     } else {
//       setSelectedTables([...selectedTables, table]);
//     }
//   };

//   // Handle number of columns change
//   const handleNumColumnsChange = (event) => {
//     setNumColumns(parseInt(event.target.value));
//   };

//   return (
//     <div>
//       <div>
//         {/* Dropdown for selecting schemas */}
//         <label htmlFor="schemas">Select Schema:</label>
//         <select id="schemas" name="schemas" value={selectedSchema} onChange={handleSchemaChange}>
//           <option value="">Select Schema</option>
//           {schemas.map(schema => (
//             <option key={schema} value={schema}>{schema}</option>
//           ))}
//         </select>
//       </div>
//       <div>
//         {/* Dropdown for selecting the number of columns */}
//         <label htmlFor="numColumns">Number of columns:</label>
//         <select id="numColumns" name="numColumns" value={numColumns} onChange={handleNumColumnsChange}>
//           {[1, 2, 3, 4, 5].map(num => (
//             <option key={num} value={num}>{num}</option>
//           ))}
//         </select>
//       </div>
//       <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
//         {/* Display tables and fields */}
//         {tables.sort().map(table => (
//           <div
//             key={table}
//             style={{
//               border: '1px solid black',
//               padding: '5px',
//               margin: '5px',
//               width: `calc(100% / ${numColumns} - 10px)`,
//               boxSizing: 'border-box'
//             }}
//           >
//             <h3 onClick={() => handleTableClick(table)}>{table}</h3>
//             {selectedTables.includes(table) && fields[selectedTables.indexOf(table)]?.map(field => (
//               <ul style={{ listStyleType: 'none', padding: 0 }} key={field}>
//                 <li>{field}</li>
//               </ul>
//             ))}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default ShowDB;

// import React from 'react';
// const apiUrl = process.env.REACT_APP_API_BASE_URL;

// function AppTest(props) {
//   return (
//     <div style={{marginTop: '5%'}}>
//      <h1> APP TEST COMPONENT</h1>
//     </div>
//   );
// }

// export default AppTest;

// // //APPTEST.JS  TESTING FREERANDOMDATA.js COMPONENT
// // import React, { useEffect, useState } from 'react';
// // import axios from 'axios';
// // import { FreeProvider } from "../Pages/FreeContext";

// // const FreeRandomData = () => {
// //   const [randomData, setRandomData] = useState(() => {
// //     // Try to retrieve data from session storage, if available
// //     const storedData = sessionStorage.getItem('randomData');
// //     const initialData = storedData ? JSON.parse(storedData) : {
// //       horses: [],
// //       jockeys: [],
// //       trainers: [],
// //       owners: [],
// //       tracks: [],
// //       races: [],
// //     };

// //     // Console log to see if there's any stored data
// //     console.log('Stored Data:', storedData,'KEVIN HARGER');

// //     return initialData;
// //   });

// //   useEffect(() => {
// //     // Function to fetch random records from the API
// //     const fetchRandomRecords = async (endpoint) => {
// //       try {
// //         const response = await axios.get(`${apiUrl}/api/random-records/${endpoint}/2`);
// //         const randomRecords = response.data;
// //         return randomRecords;
// //       } catch (error) {
// //         console.error(`Error fetching ${endpoint} data:`, error);
// //         return [];
// //       }
// //     };

// //     // Define the endpoints to fetch random records
// //     const endpoints = ['horses', 'jockeys', 'trainers', 'owners', 'tracks', 'races'];

// //     // Fetch random records for each endpoint and update the state
// //     const fetchData = async () => {
// //       const randomData = {};
// //       for (const endpoint of endpoints) {
// //         console.log(`Fetching random records for: ${endpoint}`);
// //         const records = await fetchRandomRecords(endpoint);
// //         console.log(`Fetched random records for: ${endpoint}`, records);
// //         randomData[endpoint] = records;
// //       }
// //       console.log('Fetched data:', randomData);

// //       // Save the fetched data in the session storage
// //       sessionStorage.setItem('randomData', JSON.stringify(randomData));

// //       setRandomData(randomData);
// //     };

// //     fetchData();
// //   }, []);

// //   // Console log to see the current state of randomData
// //   console.log('Updated state with random data:', randomData);

// //   return (
// //     <FreeProvider> {/* Wrap the top-level component with FreeProvider */}
// //       <div style={{marginTop:'4%' }}>APPTEST.JS  TESTING FREERANDOMDATA.js COMPONENT
// //         {Object.keys(randomData).map((endpoint) => (
// //           <div key={endpoint}>
// //             <h2>Random {endpoint.charAt(0).toUpperCase() + endpoint.slice(1)}:</h2>
// //             {randomData[endpoint].length > 0 ? (
// //               <ul>
// //                 {randomData[endpoint].map((record) => (
// //                   <li key={record.id}>{JSON.stringify(record)}</li>
// //                 ))}
// //               </ul>
// //             ) : (
// //               <div>No random {endpoint} records available.</div>
// //             )}
// //           </div>
// //         ))}
// //       </div>
// //     </FreeProvider>
// //   );
// // };

// // export default FreeRandomData;

// import React, { useContext, useEffect, useState } from "react";
// import { FreeContext } from "./FreeContext";
// import FreeRandomData from "../Modules/FreeRandomData";

// const FreeToteBoards = () => {
//   const { randomData } = useContext(FreeContext);

//   const { horses, jockeys, trainers, owners } = randomData;
//   console.log('horses, jockeys, trainers, owners:', horses, jockeys, trainers, owners, randomData)

//   useEffect(() => {
//     // Save the horse and jockey names to session storage
//     const horseNames = horses.map((horse) => horse.horse_name);
//     const jockeyNames = jockeys.map((jockey) => jockey.jockey_name);
//     sessionStorage.setItem('horses_name', JSON.stringify(horseNames));
//     sessionStorage.setItem('jockeys_name', JSON.stringify(jockeyNames));
//   }, [horses, jockeys]);

//   // Check if the data is loaded from session storage before rendering
//   const isDataLoaded =
//     horses.length > 0 &&
//     jockeys.length > 0 &&
//     trainers.length > 0 &&
//     owners.length > 0;

//   // Render a loading message if the required data is still loading or randomData is not available
//   if (!isDataLoaded) {
//     return <p>Loading...</p>;
//   }

//   return (
//     <div style={{ marginTop: '10%' }}>
//       <h2 id="freetoteboard-title">FreeToteBoard</h2>
//       {/* Render the data if it is available */}
//       <div>
//         <h3>Horses:</h3>
//         <ul>
//           {horses.map((horse) => (
//             <li key={horse.id}>{horse.horse_name}</li>
//           ))}
//         </ul>
//       </div>
//       <div>
//         <h3>Jockeys:</h3>
//         <ul>
//           {jockeys.map((jockey) => (
//             <li key={jockey.id}>{jockey.jockey_name}</li>
//           ))}
//         </ul>
//       </div>
//       <div>
//         <h3>Trainers:</h3>
//         <ul>
//           {trainers.map((trainer) => (
//             <li key={trainer.id}>{trainer.trainer_name}</li>
//           ))}
//         </ul>
//       </div>
//       <div>
//         <h3>Owners:</h3>
//         <ul>
//           {owners.map((owner) => (
//             <li key={owner.id}>{owner.owner_name}</li>
//           ))}
//         </ul>
//       </div>
//       {/* <FreeRandomData/> */}
//     </div>
//   );
// };

// export default FreeToteBoards;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// function RacesTable() {
//   const [tables, setTables] = useState([]);
//   const [selectedTable, setSelectedTable] = useState('');
//   const [columns, setColumns] = useState([]);
//   const [selectedColumn, setSelectedColumn] = useState('');
//   const [searchValue, setSearchValue] = useState('');
//   const [records, setRecords] = useState([]);

//   useEffect(() => {
//     async function fetchTables() {
//       try {
//         const response = await axios.get(' ${apiUrl}/api/tables');
//         setTables(response.data);
//       } catch (error) {
//         console.error('Error fetching tables:', error);
//       }
//     }

//     fetchTables();
//   }, []);

//   useEffect(() => {
//     async function fetchColumns() {
//       if (selectedTable) {
//         try {
//           const response = await axios.get(`${apiUrl}/api/${selectedTable}/fields`);
//           setColumns(response.data);
//         } catch (error) {
//           console.error('Error fetching columns:', error);
//         }
//       } else {
//         setColumns([]);
//       }
//     }

//     fetchColumns();
//   }, [selectedTable]);

//   const handleSearch = async () => {
//     if (selectedTable && selectedColumn && searchValue) {
//       try {
//         const response = await axios.get(`${apiUrl}/api/${selectedTable}/query?column=${selectedColumn}&value=${searchValue}`);
//         setRecords(response.data);
//       } catch (error) {
//         console.error('Error fetching records:', error);
//       }
//     }
//   };

//   return (
//     <>
//       <h1>RACES</h1>
//       <label>
//         Select Table:
//         <select value={selectedTable} onChange={(e) => setSelectedTable(e.target.value)}>
//           <option value="">Select Table</option>
//           {tables.map((table) => (
//             <option key={table} value={table}>
//               {table}
//             </option>
//           ))}
//         </select>
//       </label>

//       {selectedTable && (
//         <>
//           <label>
//             Select Column:
//             <select value={selectedColumn} onChange={(e) => setSelectedColumn(e.target.value)}>
//               <option value="">Select Column</option>
//               {columns.map((column) => (
//                 <option key={column} value={column}>
//                   {column}
//                 </option>
//               ))}
//             </select>
//           </label>

//           <label>
//             Search Value:
//             <input
//               type="text"
//               value={searchValue}
//               onChange={(e) => setSearchValue(e.target.value)}
//             />
//           </label>

//           <button onClick={handleSearch}>Search</button>
//         </>
//       )}

//       <table>
//         <thead>
//           <tr>
//             {columns.slice(0, 12).map((column) => (
//               <th key={column}>{column}</th>
//             ))}
//           </tr>
//         </thead>
//         <tbody>
//           {records.slice(0, 10).map((record, index) => (
//             <tr key={index}>
//               {columns.slice(0, 12).map((column) => (
//                 <td key={column}>{record[column]}</td>
//               ))}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </>
//   );
// }

// export default RacesTable;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// function TableQueryComponent() {
//   const [tables, setTables] = useState([]);
//   const [selectedTable, setSelectedTable] = useState('');
//   const [columns, setColumns] = useState([]);
//   const [selectedColumn, setSelectedColumn] = useState('');
//   const [queryValue, setQueryValue] = useState('');
//   const [queryResults, setQueryResults] = useState([]);
//   const [error, setError] = useState('');

//   useEffect(() => {
//     // Fetch the list of tables from the server
//     async function fetchTables() {
//       try {
//         const response = await axios.get(' ${apiUrl}/api/tables');
//         setTables(response.data);
//       } catch (error) {
//         setError('Error fetching tables. Please try again later.');
//       }
//     }
//     fetchTables();
//   }, []);

//   // Fetch columns of the selected table when a table is selected
//   useEffect(() => {
//     async function fetchColumns() {
//       try {
//         if (selectedTable) {
//           const response = await axios.get(`${apiUrl}/api/${selectedTable}/fields`);
//           setColumns(response.data);
//           setSelectedColumn('');
//           setQueryValue('');
//           setQueryResults([]);
//           setError('');
//         }
//       } catch (error) {
//         setError('Error fetching columns. Please try again later.');
//       }
//     }
//     fetchColumns();
//   }, [selectedTable]);

//   // Function to perform the query when the button is clicked
//     async function performQuery() {
//     try {
//       if (selectedTable && selectedColumn && queryValue) {
//         const response = await axios.get(
//           `${apiUrl}/api/query/${selectedTable}/${selectedColumn}/${queryValue}`
//         );
//         setQueryResults(response.data);
//         setError('');
//       } else {
//         setQueryResults([]);
//       }
//     } catch (error) {
//       setError('Error performing the query. Please try again later.');
//     }
//   }
//   console.log('KH-350 ',selectedTable,selectedColumn, queryResults);

//   return (
//     <div>
//       <h1>Table Query Component</h1>
//       {error && <div>{error}</div>}
//       <label>
//         Select a Table:
//         <select value={selectedTable} onChange={(e) => setSelectedTable(e.target.value)}>
//           <option value="">Select a table</option>
//           {tables.map((table) => (
//             <option key={table} value={table}>
//               {table}
//             </option>
//           ))}
//         </select>
//       </label>
//       <br />
//       {selectedTable && (
//         <div>
//           <label>
//             Select a Column:
//             <select value={selectedColumn} onChange={(e) => setSelectedColumn(e.target.value)}>
//               <option value="">Select a column</option>
//               {columns.map((column) => (
//                 <option key={column} value={column}>
//                   {column}
//                 </option>
//               ))}
//             </select>
//           </label>
//           <br />
//           {selectedColumn && (
//             <div>
//               <label>
//                 Enter a Value:
//                 <input type="text" value={queryValue} onChange={(e) => setQueryValue(e.target.value)} />
//               </label>
//               <br />
//               <button onClick={performQuery}>Perform Query</button> {/* Add the button here */}
//               <br />
//               <h2>Query Results:</h2>
//               <ul>
//                 {queryResults.map((result, index) => (
//                   <li key={index}>{JSON.stringify(result)}</li>
//                 ))}
//               </ul>
//             </div>
//           )}
//         </div>
//       )}
//     </div>
//   );
// }

// export default TableQueryComponent;

// import React, { useContext, useState, useEffect } from "react";
// import axios from "axios";
// import { RaceContext } from "./RaceContext";
// import { useLocation, useNavigate } from "react-router-dom";
// import horseImage from "../Pages/Images/horse3.jpg";

// const Bets = () => {
//   const { race, setRace } = useContext(RaceContext);
//   const [selectedBet, setSelectedBet] = useState("Win");
//   const [selectedAmount, setSelectedAmount] = useState(2);
//   const [selectedHorses, setSelectedHorses] = useState([]);
//   const [cachedBetTypes, setCachedBetTypes] = useState([]);
//   const [horses, setHorses] = useState([]);
//   const [betTypeDescription, setBetTypeDescription] = useState("");
//   const [showDescription, setShowDescription] = useState(false);
//   const [bets, setBets] = useState([]);
//   const [searchTerm, setSearchTerm] = useState("");
//   const navigate = useNavigate();
//   const location = useLocation();
//   const searchParams = new URLSearchParams(location.search);
//   const raceId = searchParams.get("raceId");
//   const horseIds = searchParams.get("horseIds");
//   const horseNames = searchParams.get("horseNames");

//   const horseIdArray = horseIds ? horseIds.split(",") : [];
//   const horseNameArray = horseNames ? horseNames.split(",") : [];

//   const [raceCardData] = useState([
//     { raceId: raceId, horseIds: horseIds, horseNames: horseNames },
//   ]);

//   useEffect(() => {
//     console.log("Race ID:", raceId);
//     console.log("Horse IDs:", horseIds);
//     console.log("Horse Names:", horseNames);
//   }, [raceId, horseIds, horseNames]);

//   useEffect(() => {
//     const fetchBetTypes = async () => {
//       try {
//         const response = await axios.get(`${apiUrl}/api/bet_types`);
//         setCachedBetTypes(response.data);
//       } catch (error) {
//         console.log(error);
//       }
//     };

//     fetchBetTypes();

//     fetchHorses();
//   }, []);

//   const fetchHorses = async () => {
//     try {
//       const horses = horseIdArray.map((horseId, index) => ({
//         value: horseId,
//         label: horseNameArray[index],
//       }));
//       setHorses(horses);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     fetchHorses();
//   }, []);

//   useEffect(() => {
//     if (selectedBet) {
//       const selectedBetObject = cachedBetTypes.find(
//         (betType) => betType.bet_type === selectedBet
//       );
//       if (selectedBetObject) {
//         setBetTypeDescription(selectedBetObject.description);
//       } else {
//         setBetTypeDescription("");
//       }
//     } else {
//       setBetTypeDescription("");
//     }
//   }, [selectedBet, cachedBetTypes]);

//   const handleBetChange = (event) => {
//     const selectedValue = event.target.value;
//     setSelectedBet(selectedValue);
//   };

//   const handleAmountChange = (event) => {
//     setSelectedAmount(event.target.value);
//   };

//   const handleHorseChange = (event) => {
//     const selectedHorsesArray = Array.from(
//       event.target.selectedOptions,
//       (option) => ({
//         label: option.label,
//         value: option.value,
//       })
//     );
//     const limitedSelectedHorses = selectedHorsesArray.slice(0, 15); // Limit to a maximum of 15 horses
//     setSelectedHorses(limitedSelectedHorses);
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     const subTotal = selectedAmount * selectedHorses.length; // Calculate sub-total

//     const betDetails = {
//       bet_amount: selectedAmount,
//       subTotal: subTotal, // Add sub-total to bet details
//       betType: selectedBet,
//       horses: selectedHorses,
//     };
//     console.log("Bet details:", betDetails);
//     setBets([...bets, betDetails]);
//     setSelectedAmount(0);
//     setSelectedBet("");
//     setSelectedHorses([]);
//   };

//   const handleRemove = (indexToRemove) => {
//     setBets((prevBets) =>
//       prevBets.filter((bet, index) => index !== indexToRemove)
//     );
//   };

//   const handleFinalize = () => {
//     console.log("Finalizing order with bets:", bets);

//     // Iterate over each bet in the `bets` array
//     bets.forEach((bet, index) => {
//       // Create an object to store the bet details
//       const betData = {
//         bet_amount: bet.bet_amount,
//         bet_total: betTotal,
//         bet_type: bet.betType,
//         race_id: raceId,
//         user_id: localStorage.getItem("userId"), // Add the user ID from localStorage

//       };

//       // Iterate over selected horses for the current bet
//       bet.horses.forEach((horse, horseIndex) => {
//         // Assign the horse ID to the corresponding horse_id# column
//         betData[`horse_id${horseIndex + 1}`] = horse.value;
//       });
//       console.log(betData);

//       // Send a POST request to the `/bets` endpoint for each bet item
//       axios
//         .post(`${apiUrl}/api/bets`, betData)
//         .then((response) => {})
//         .catch((error) => {
//           console.log(`Error adding bet ${index + 1}:`, error);

//         });
//     });

//     navigate(`/race?id=${raceId}`);
//     // Call API or perform other actions to finalize the order
//   };

//   const betTotal = bets.reduce(
//     (total, bet) => total + parseInt(bet.subTotal),
//     0
//   ); // Calculate total amount based on sub-total

//   const handleGoBack = () => {
//     navigate(-1);
//   };

//   // Filter horses based on search term
//   // const filteredHorses = horses.filter((horse) =>
//   //   horse.label.toLowerCase().includes(searchTerm.toLowerCase())
//   // );

//   const filteredHorses = horses.filter((horse) =>
//   horse.label && horse.label.toLowerCase().includes(searchTerm.toLowerCase())
// );

//   return (
//     <>
//       <button onClick={handleGoBack} style={{marginLeft:'3vw', marginTop: "4vh" }}>
//         Go Back
//       </button>
//       <div style={{ display: "flex" }}>
//         <div style={{ width: "34%", marginLeft: "3vw", marginTop: "1vw" }}>
//           <figure
//             style={{
//               maxWidth: "75%",
//               marginTop: "-1vh",
//             }}
//           >
//             <img
//               src={horseImage}
//               alt="horserace"
//               style={{
//                 marginLeft: "2.5vw",
//                 maxWidth: "75%",
//                 marginTop: "1vh",
//               }}
//             />
//           </figure>
//           <div style={{paddingRight:'1vw'}}>
//           To place a bet, select a bet type and the horses you want to bet on,
//           then enter your bet amount and select the horse(s) and click "Place
//           Bet". The odds on Bets can vary depending on the number of possible
//           outcomes and the amount of money wagered on each outcome. <p />
//           The odds are calculated by multiplying the odds of each individual
//           selection. For example, if the odds of the first selection are 2:1,
//           the odds of the second selection are 3:1, and the odds of the third
//           selection are 4:1, the overall odds of the Pick 3 bet would be 2 x 3 x
//           4 = 24:1. <p />
//           However, it's important to note that the odds and payouts can differ
//           depending on the specific track and race, as well as the amount of
//           money wagered by other bettors. Statistics for each horse, jockey,
//           trainer, etc can be found in the menu system.
//           </div>
//         </div>
//         <form
//           onSubmit={handleSubmit}
//           style={{
//             width: "32vw",
//             marginTop: "-1vh",
//             marginRight: "14vw",
//             marginLeft:'1%',
//             border: '1px none',
//             boxShadow: '3px 3px 6px 8px darkgrey'

//             // backgroundColor:'red'
//           }}
//         >
//           <div
//             style={{width: "18vw",marginTop: "-1vh",}}
//           >
//             <label htmlFor="bet-type">Select Bet Type:</label>
//             <select
//               id="bet-type"
//               onChange={handleBetChange}
//               value={selectedBet}
//             >
//               <option value="">--Select Bet Type--</option>
//               {cachedBetTypes.map((betType) => (
//                 <option key={betType.id} value={betType.bet_type}>
//                   {betType.bet_type}
//                 </option>
//               ))}
//             </select>
//             <input
//               type="checkbox"
//               checked={showDescription}
//               onChange={() => setShowDescription(!showDescription)}
//             />
//             <label >Show Description</label>

//             {showDescription && (
//               <div>
//                 <p>{betTypeDescription}</p>
//               </div>
//             )}
//           </div>

//           <div
//             style={{
//               width: "20vw",
//               marginTop: "1vh",
//             }}
//           >

//             <label htmlFor="amount">Bet Amount:</label>
//             <input
//               id="amount"
//               type="number"
//               min="0"
//               step="1"
//               value={selectedAmount}
//               onChange={handleAmountChange}
//             />
//           </div>
//           <div
//             style={{
//               width: "20vw",
//               marginTop: "-1vh",
//             }}
//           >
//             <label htmlFor="horses">Select Horses:</label>
//             <input style={{width: "18vw"}}
//               type="text"
//               placeholder="Search Horses"
//               value={searchTerm}
//               onChange={(e) => setSearchTerm(e.target.value)}
//             />
//             <select
//               id="horses"
//               multiple
//               onChange={handleHorseChange}
//               value={selectedHorses.map((horse) => horse.value)} style={{width: "20vw"}}
//             >
//               {filteredHorses.map((horse) => (
//                 <option key={horse.value} value={horse.value}>
//                   {horse.label}
//                 </option>
//               ))}
//             </select>
//           </div>
//           <div>
//             <button
//               type="submit"
//               disabled={!selectedBet || !selectedHorses.length}
//             >
//               Add Bet
//             </button>
//           </div>
//           <div>
//             {bets.length > 0 && (
//               <table>
//                 <thead>
//                   <tr>
//                     <th>Bet Type</th>
//                     <th>Amount</th>
//                     <th>Horses</th>
//                     <th>Sub-Total</th>
//                     <th>Actions</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {bets.map((bet, index) => (
//                     <tr key={index}>
//                       <td>{bet.betType}</td>
//                       <td>${bet.bet_amount}</td>

//                       {/* <td>{bet.horses.join(", ")}</td> */}
//                       <td>
//                         {bet.horses.map((horse) => horse.label).join(", ")}
//                       </td>

//                       <td>${bet.subTotal}</td>
//                       <td>
//                         <button
//                           type="button"
//                           onClick={() => handleRemove(index)}
//                         >
//                           Remove
//                         </button>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//                 <tfoot>
//                   <tr>
//                     <td colSpan="3">Total Amount:</td>
//                     <td>${betTotal.toFixed(2)}</td>
//                     <td>
//                       <button type="button" onClick={handleFinalize}>
//                         Finalize Order
//                       </button>
//                     </td>
//                   </tr>
//                 </tfoot>
//               </table>
//             )}
//           </div>
//         </form>
//       </div>
//          </>
//   );
// };

// export default Bets;

//RaceDistanceFactor Component:
//calculateRaceDistanceFactor Component:

//calculateRaceDistanceFactor Component:

// import React, { useState, useEffect } from 'react';
// import { calculateRaceDistanceFactor } from '../apiOdds';

// function CalculateRaceDistanceFactor({ raceId, raceClass, raceDistance }) {
//   const [numWinsSameClassDistance, setNumWinsSameClassDistance] = useState(null);

//   useEffect(() => {
//     // Make an API request to calculate race distance factor
//     async function fetchRaceDistanceFactor() {
//       try {
//         const response = await calculateRaceDistanceFactor(raceId, raceClass, raceDistance);
//         setNumWinsSameClassDistance(response[0].num_wins_same_class_distance);
//       } catch (error) {
//         console.error('Error calculating race distance factor:', error.message);
//       }
//     }

//     fetchRaceDistanceFactor();
//   }, [raceId, raceClass, raceDistance]);

//   return (
//     <div>
//       <h2>Race Distance Factor for Race {raceId}</h2>
//       {numWinsSameClassDistance !== null ? (
//         <p>Number of Wins with the Same Class and Distance: {numWinsSameClassDistance}</p>
//       ) : (
//         <p>Loading...</p>
//       )}
//     </div>
//   );
// }

// export default CalculateRaceDistanceFactor;
