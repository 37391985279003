
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Modal from "react-modal";
import { useLocation, useNavigate } from "react-router-dom";
import FormatKey from "../Modules/FormatKey";
import { RaceContext } from "../Pages/RaceContext";
import Clock from "../Modules/MathTime/Clock";
import EnterRaceForm from "../Users/EnterRaceForm"; // Import EnterRaceForm

const apiUrl = process.env.REACT_APP_API_BASE_URL;


const RaceCards = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const raceId = searchParams.get("raceId");
  const trackId = searchParams.get("trackId");
  const [raceData, setRaceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newHorsesData, setNewHorsesData] = useState([]);
  const [horseDetails, setHorseDetails] = useState({});
  const [jockeyDetails, setJockeyDetails] = useState({});
  const [trainerDetails, setTrainerDetails] = useState({});
  const [ownerDetails, setOwnerDetails] = useState({});
  const [selectedHorseId, setSelectedHorseId] = useState(null); // Changed to single selected horse ID
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

  const {
    setRace,
    setCurrentRaceId,
    setNavigateData,
    setSelectedHorseIds: setRaceContextHorseIds,
    setSelectedHorseNames,
    numHorses,
    setNumHorses,
    currentRaceId,
    trackName,
    postTime,
    setPostTime,
  } = useContext(RaceContext);

  // useEffect(() => {
  //   const fetchRaceData = async () => {
  //     try {
  //       const raceNumHorsesResponse = await axios.get(
  //         `${apiUrl}/api/races/${raceId}`
  //       );
  //       let raceNumHorses = raceNumHorsesResponse.data.num_horses;

  //       setNumHorses(raceNumHorses);

  //       if (raceNumHorses < 6 || raceNumHorses > 12) {
  //         raceNumHorses = Math.floor(Math.random() * 7) + 9;
  //       }

  //       const raceResponse = await axios.get(
  //         `${apiUrl}/api/query/current_races/race_id/${raceId}`
  //       );
  //       const today = new Date();
  //       today.setUTCHours(0, 0, 0, 0);

  //       let currentRaceResults = raceResponse.data.filter((result) => {
  //         const raceDate = new Date(result.race_date);
  //         const trackIdInt = parseInt(result.track_id);
  //         const raceIdInt = parseInt(result.race_id);
  //         raceDate.setUTCHours(0, 0, 0, 0);
  //         if (typeof result.post_time === "string") {
  //           setPostTime(result.post_time);
  //         }
  //         // console.log("RACECARD RESULT POST TIME:", result.post_time);
  //         return (
  //           trackIdInt === parseInt(trackId) &&
  //           raceIdInt === parseInt(raceId) &&
  //           raceDate >= today
  //         );
  //       });

  //       if (currentRaceResults.length === 0) {
  //         console.warn(`Not enough valid races found for race ID ${raceId}`);
  //         setRaceData([]);
  //         setLoading(false);
  //         return;
  //       }

  //       const patchPromises = [];

  //       for (let currentRaceResult of currentRaceResults) {
  //         if (currentRaceResult.num_horses !== raceNumHorses) {
  //           const patchData = {
  //             num_horses: raceNumHorses,
  //             num_jockeys: raceNumHorses,
  //           };
  //           patchPromises.push(
  //             axios.patch(
  //               `${apiUrl}/api/current_races/${currentRaceResult.id}`,
  //               patchData
  //             )
  //           );
  //         }
  //       }

  //       for (let currentRaceResult of currentRaceResults) {
  //         const horseKeys = Object.keys(currentRaceResult).filter((key) =>
  //           key.startsWith("horse_id")
  //         );
  //         const qualifiedHorseKeys = horseKeys.filter(
  //           (horseKey) => currentRaceResult[horseKey] >= 1
  //         );

  //         const jockeyKeys = Object.keys(currentRaceResult).filter((key) =>
  //           key.startsWith("jockey_id")
  //         );
  //         const qualifiedJockeyKeys = jockeyKeys.filter(
  //           (jockeyKey) => currentRaceResult[jockeyKey] >= 1
  //         );

  //         if (
  //           qualifiedHorseKeys.length < raceNumHorses ||
  //           qualifiedJockeyKeys.length < 6
  //         ) {
  //           const randomValue = Math.floor(Math.random() * 7) + 9;
  //           const additionalHorses = await axios.get(
  //             `${apiUrl}/api/random-records/horses/${randomValue}`
  //           );
  //           const additionalJockeys = await axios.get(
  //             `${apiUrl}/api/random-records/jockeys/${randomValue}`
  //           );

  //           let patchData = {};

  //           let horseCounter = qualifiedHorseKeys.length;

  //           while (
  //             horseCounter < raceNumHorses &&
  //             horseCounter < additionalHorses.data.length
  //           ) {
  //             const horseKey = `horse_id${horseCounter + 1}`;
  //             currentRaceResult[horseKey] =
  //               additionalHorses.data[horseCounter].id;
  //             patchData[horseKey] = additionalHorses.data[horseCounter].id;
  //             horseCounter++;
  //           }

  //           let jockeyCounter = 0;
  //           for (let jockeyKey of jockeyKeys) {
  //             if (
  //               currentRaceResult[jockeyKey] < 1 &&
  //               jockeyCounter < additionalJockeys.data.length
  //             ) {
  //               currentRaceResult[jockeyKey] =
  //                 additionalJockeys.data[jockeyCounter].id;
  //               patchData[jockeyKey] = additionalJockeys.data[jockeyCounter].id;
  //               jockeyCounter++;
  //             }
  //           }

  //           if (Object.keys(patchData).length > 0) {
  //             patchPromises.push(
  //               axios.patch(
  //                 `${apiUrl}/api/current_races/${currentRaceResult.id}`,
  //                 patchData
  //               )
  //             );
  //           } else {
  //             console.warn(
  //               "No data to patch for race ID:",
  //               currentRaceResult.id
  //             );
  //           }
  //         }
  //       }

  //       await Promise.all(patchPromises);
  //       setRaceData(currentRaceResults);

  //       const horseIds = currentRaceResults.flatMap(race => 
  //         Object.keys(race)
  //           .filter(key => key.startsWith("horse_id"))
  //           .map(key => race[key])
  //           .filter(id => id >= 1)
  //       );
  //       setRaceContextHorseIds(horseIds); // Ensure these are set in context
  //       // Set the selectedHorseId only if it is not already set
  //       if (!selectedHorseId) {
  //         setSelectedHorseId(horseIds[0]); // Default to the first horse ID
  //       }
  //       // console.log("Selected Horse IDs:", horseIds); // Ensure this prints correct IDs
  //     } catch (error) {
  //       console.error("Error fetching race data:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchRaceData();

  //   const fetchInterval = setInterval(() => {
  //     fetchRaceData();
  //   }, 3000);

  //   return () => clearInterval(fetchInterval);
  // }, [raceId, trackId, setRaceContextHorseIds, selectedHorseId]);

// Utility to extract valid IDs for horses, jockeys, etc.



const getValidKeys = (object, prefix) =>
  Object.keys(object)
    .filter((key) => key.startsWith(prefix))
    .map((key) => object[key])
    .filter((id) => id >= 1);



    useEffect(() => {
      const fetchRaceData = async () => {
        try {
          // Parallel API calls for race information
          const [raceNumHorsesResponse, raceResponse] = await Promise.all([
            axios.get(`${apiUrl}/api/races/${raceId}`),
            axios.get(`${apiUrl}/api/query/current_races/race_id/${raceId}`),
          ]);
    
          // Step 1: Get number of horses in the race
          let raceNumHorses = raceNumHorsesResponse.data.num_horses;
          setNumHorses(raceNumHorses);
    
          // Ensure number of horses is valid
          if (raceNumHorses < 6 || raceNumHorses > 12) {
            raceNumHorses = Math.floor(Math.random() * 7) + 9;
          }
    
          // Step 2: Filter races based on trackId, raceId, and date
          const today = new Date();
          today.setUTCHours(0, 0, 0, 0);
    
          const currentRaceResults = raceResponse.data.filter((result) => {
            const raceDate = new Date(result.race_date);
            raceDate.setUTCHours(0, 0, 0, 0);
            return (
              parseInt(result.track_id) === parseInt(trackId) &&
              parseInt(result.race_id) === parseInt(raceId) &&
              raceDate >= today
            );
          });
    
          // Handle case: No valid races found
          if (currentRaceResults.length === 0) {
            console.warn(`No valid races found for race ID ${raceId}`);
            setRaceData([]);
            setLoading(false);
            return;
          }
    
          // Step 3: Validate and patch missing data
          const patchPromises = [];
          for (let currentRaceResult of currentRaceResults) {
            // Check and patch num_horses if required
            if (currentRaceResult.num_horses !== raceNumHorses) {
              patchPromises.push(
                axios.patch(`${apiUrl}/api/current_races/${currentRaceResult.id}`, {
                  num_horses: raceNumHorses,
                  num_jockeys: raceNumHorses,
                })
              );
            }
    
            // Ensure enough horses and jockeys are present
            const horseIds = getValidKeys(currentRaceResult, "horse_id");
            const jockeyIds = getValidKeys(currentRaceResult, "jockey_id");
    
            if (horseIds.length < raceNumHorses || jockeyIds.length < 6) {
              const [additionalHorses, additionalJockeys] = await Promise.all([
                axios.get(`${apiUrl}/api/random-records/horses/${raceNumHorses}`),
                axios.get(`${apiUrl}/api/random-records/jockeys/${raceNumHorses}`),
              ]);
    
              let patchData = {};
    
              // Fill missing horses
              for (let i = horseIds.length; i < raceNumHorses; i++) {
                const horseKey = `horse_id${i + 1}`;
                const newHorse = additionalHorses.data[i - horseIds.length];
                if (newHorse) {
                  patchData[horseKey] = newHorse.id;
                  currentRaceResult[horseKey] = newHorse.id;
                }
              }
    
              // Fill missing jockeys
              for (let i = jockeyIds.length; i < 6; i++) {
                const jockeyKey = `jockey_id${i + 1}`;
                const newJockey = additionalJockeys.data[i - jockeyIds.length];
                if (newJockey) {
                  patchData[jockeyKey] = newJockey.id;
                  currentRaceResult[jockeyKey] = newJockey.id;
                }
              }
    
              if (Object.keys(patchData).length > 0) {
                patchPromises.push(
                  axios.patch(`${apiUrl}/api/current_races/${currentRaceResult.id}`, patchData)
                );
              }
            }
          }
    
          // Wait for all patches to complete
          await Promise.all(patchPromises);
    
          // Step 4: Update state with processed data
          setRaceData(currentRaceResults);
    
          const allHorseIds = currentRaceResults.flatMap((race) =>
            getValidKeys(race, "horse_id")
          );
          setRaceContextHorseIds(allHorseIds);
    
          if (!selectedHorseId) {
            setSelectedHorseId(allHorseIds[0]); // Default to first horse ID
          }
        } catch (error) {
          console.error("Error fetching race data:", error);
        } finally {
          setLoading(false);
        }
      };
    
      fetchRaceData();
    }, [raceId, trackId, setRaceContextHorseIds, selectedHorseId]);
    




  useEffect(() => {
    const raceDetails = async () => {
      try {
        const newHorses = [];
        const fetchedHorseDetails = {};
        const fetchedJockeyDetails = {};
        const fetchedTrainerDetails = {};
        const fetchedOwnerDetails = {};
        const fetchedHorseIdsSet = new Set();
        const fetchedJockeyIdsSet = new Set();
        const fetchedTrainerIdsSet = new Set();
        const fetchedOwnerIdsSet = new Set();
  
        const currentRaceId = raceData.length > 0 ? raceData[0].id : null;
        if (currentRaceId) {
          setCurrentRaceId(currentRaceId);
        }
  
        for (let i = 0; i < raceData.length; i++) {
          const horseIds = Object.keys(raceData[i])
            .filter((key) => key.startsWith("horse_id"))
            .map((key) => raceData[i][key])
            .filter((id) => id >= 1);
          const jockeyIds = Object.keys(raceData[i])
            .filter((key) => key.startsWith("jockey_id"))
            .map((key) => raceData[i][key])
            .filter((id) => id >= 1);
  
          // Assigning post numbers to horses
          horseIds.forEach((horseId, index) => {
            newHorses.push({
              horse_id: horseId,
              post_number: index + 1, // Assign post number
            });
          });
  
          // Fetch horse details
          for (const id of horseIds) {
            if (!fetchedHorseIdsSet.has(id)) {
              const response = await axios.get(`${apiUrl}/api/horses/${id}`);
              fetchedHorseDetails[id] = response.data.horse_name;
              fetchedHorseIdsSet.add(id);
            }
          }
  
          // Fetch jockey details
          for (let j = 0; j < jockeyIds.length; j++) {
            const horseId = horseIds[j];
            const jockeyId = jockeyIds[j];
            if (!fetchedJockeyIdsSet.has(jockeyId)) {
              const response = await axios.get(`${apiUrl}/api/jockeys/${jockeyId}`);
              fetchedJockeyDetails[horseId] = {
                horseName: fetchedHorseDetails[horseId],
                jockeyName: response.data.jockey_name,
              };
              fetchedJockeyIdsSet.add(jockeyId);
            }
          }
  
          // Fetch trainer details
          for (const horseId of horseIds) {
            if (!fetchedTrainerIdsSet.has(horseId)) {
              const response = await axios.get(`${apiUrl}/api/horses/${horseId}`);
              const trainerId = response.data.trainer_id;
              const trainerResponse = await axios.get(`${apiUrl}/api/trainers/${trainerId}`);
              fetchedTrainerDetails[horseId] = {
                horseName: fetchedHorseDetails[horseId],
                trainerName: trainerResponse.data.trainer_name,
              };
              fetchedTrainerIdsSet.add(horseId);
            }
          }
  
          // Fetch owner details
          for (const horseId of horseIds) {
            if (!fetchedOwnerIdsSet.has(horseId)) {
              const response = await axios.get(`${apiUrl}/api/horses/${horseId}`);
              const ownerId = response.data.owner_id;
              const ownerResponse = await axios.get(`${apiUrl}/api/owners/${ownerId}`);
              fetchedOwnerDetails[horseId] = {
                horseName: fetchedHorseDetails[horseId],
                ownerName: ownerResponse.data.owner_name,
              };
              fetchedOwnerIdsSet.add(horseId);
            }
          }
        }
  
        // Update state with fetched data
        setNewHorsesData(newHorses);
        setHorseDetails(fetchedHorseDetails);
        setJockeyDetails(fetchedJockeyDetails);
        setTrainerDetails(fetchedTrainerDetails);
        setOwnerDetails(fetchedOwnerDetails);
      } catch (error) {
        console.error("Error fetching race details:", error);
      }
    };
  
    raceDetails();
  }, [raceData, setCurrentRaceId]);
  





  useEffect(() => {
    const fetchHorseDetails = async () => {
      try {
        let promises = [];

        newHorsesData.forEach((horse) => {
          promises.push(
            axios.get(`${apiUrl}/api/horses/${horse.horse_id}`)
          );
        });

        const responses = await Promise.all(promises);

        let fetchedDetails = {};

        responses.forEach((response) => {
          const horseData = response.data;
          fetchedDetails[horseData.id] = horseData.horse_name;
        });

        setHorseDetails((prevHorseDetails) => ({
          ...prevHorseDetails,
          ...fetchedDetails,
        }));
      } catch (error) {
        console.error("Error fetching horse details:", error);
      }
    };

    const fetchData = async () => {
      if (newHorsesData.length > 0) {
        await fetchHorseDetails();
      }
    };

    fetchData();
  }, [newHorsesData]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleNavigateToBets = async () => {
    setRace(raceData);
    setNavigateData({
      raceId,
      numHorses,
    });
    setRaceContextHorseIds(
      newHorsesData.slice(0, numHorses).map((h) => h.horse_id)
    );
    setSelectedHorseNames(
      newHorsesData
        .slice(0, numHorses)
        .map((h) => horseDetails[h.horse_id] || "Fetching...")
    );
    navigate("/bets");
  };

  const handleHorseSelection = (horse) => {
    setSelectedHorseId(horse.horse_id);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div style={{marginLeft:"1%", marginTop: "2%", display: "flex" }}>
      <div style={{ flex: "1", marginRight: "2%",marginTop:"1vh" }}>
        <button onClick={handleGoBack} style={{ marginLeft: "3px" }}>
          Go Back
        </button>
        <button onClick={handleNavigateToBets} style={{ marginLeft: "1vw" }}>
          Place Bet
        </button>
        <button onClick={() => navigate("/bet-odds")} style={{ marginLeft: "1vw" }}>
          Bet Odds
        </button>
        <button onClick={() => navigate("/predict-race")} style={{ marginLeft: "1vw" }}>
          Predict Race
        </button>
        <button onClick={() => navigate("/morning-line-odds")} style={{ marginLeft: "1vw" }}>
          Morning Line Odds
        </button>
        <Clock showCountdown={true} />
        {/* <button onClick={openModal} style={{ marginLeft: "1vw" }}>
          Enter Race
        </button> */}
  
        <h2>CURRENT RACE CARD:</h2>
        {loading ? (
          <p>Loading race data...</p>
        ) : raceData.length > 0 ? (
          <>
            <table>
              <thead>
                <tr>
                  {Object.keys(raceData[0])
                    .slice(0, 9)
                    .map((key, idx) => (
                      <th key={idx}>
                        <FormatKey keyText={key} />
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {raceData.map((race, rIdx) => (
                  <tr key={rIdx}>
                    {Object.keys(race)
                      .slice(0, 9)
                      .map((key, cIdx) => (
                        <td key={cIdx}>{race[key]}</td>
                      ))}
                  </tr>
                ))}
              </tbody>
            </table>
  
            <h2>RACE DETAILS:</h2>
            <table>
              <thead>
                <tr>
                  <th>Post #</th>
                  <th>Horse #</th>
                  <th>Horse</th>
                  <th>Jockey</th>
                  <th>Trainer</th>
                  <th>Owner</th>
                </tr>
              </thead>
              <tbody>
                {newHorsesData.slice(0, numHorses).map((horse, index) => (
                  <tr key={index}>
                    {/* <td>
                      <input
                        type="radio"
                        name="selectedHorse"
                        checked={selectedHorseId === horse.horse_id}
                        onChange={() => handleHorseSelection(horse)}
                      />
                    </td> */}
                    <td>{index + 1}</td>
                    <td>{horse.horse_id}</td>
                    <td
                      onClick={() => navigate(`/page-horse/${horse.horse_id}`)}
                      style={{ cursor: "pointer" }}
                    >
                      {horseDetails[horse.horse_id] || "Fetching..."}
                    </td>
                    <td
                      onClick={() => navigate(`/page-jockey/${horse.horse_id}`)}
                      style={{ cursor: "pointer" }}
                    >
                      {jockeyDetails[horse.horse_id]
                        ? jockeyDetails[horse.horse_id].jockeyName
                        : "Fetching..."}
                    </td>
                    <td
                      onClick={() => {
                        navigate(
                          `/page-trainer/${trainerDetails[horse.horse_id]?.trainerId}`
                        );
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {trainerDetails[horse.horse_id]
                        ? trainerDetails[horse.horse_id].trainerName
                        : "Fetching..."}
                    </td>
                    <td
                      onClick={() => {
                        navigate(
                          `/page-owner/${ownerDetails[horse.horse_id]?.ownerId}`
                        );
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {ownerDetails[horse.horse_id]
                        ? ownerDetails[horse.horse_id].ownerName
                        : "Fetching..."}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <p>No race data available.</p>
        )}
  
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Enter Race"
          ariaHideApp={false}
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
            },
          }}
        >
          <EnterRaceForm />
          <button onClick={closeModal}>Close</button>
        </Modal>
      </div>
    </div>
  );
  
};

export default RaceCards;
