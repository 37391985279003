

import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Clock from "../Modules/MathTime/Clock";
import { useNavigate } from "react-router-dom";
import { RaceContext } from '../Pages/RaceContext';
import horseImage from "../Pages/Images/horse1.jpg";
import "../Styles/ProgramCard.css";
import FormatKey from "../Modules/FormatKey";

console.log('API URL:', process.env.REACT_APP_API_BASE_URL);
// console.log(process.env);

const apiUrl = process.env.REACT_APP_API_BASE_URL;

if (!apiUrl) {
  throw new Error("API URL is not defined. Please set REACT_APP_API_BASE_URL in the .env file.");
}

const ProgramCard = (props) => {
  const [tracks, setTracks] = useState([]);
  const [selectedLetter, setSelectedLetter] = useState("");
  const [numRows, setNumRows] = useState(25);
  const [selectedTrack, setSelectedTrack] = useState(null);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRace, setSelectedRace] = useState(null);


  const navigate = useNavigate();
  const { currentRaceId,  setCurrentRaceId, setTrackId, setNavigateData, race, setRace, setNumHorses, setTrackName } = useContext(RaceContext);

  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/programcard-tracks`);
        setTracks(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    if (selectedLetter) {
      fetchData();
    } else {
      setTracks([]);
    }
  }, [selectedLetter, numRows]);

  const handleLetterClick = (letter) => {
    setSelectedLetter(letter);
    setTracks([]);
  };

  const handleNumRowsChange = (event) => {
    setNumRows(Number(event.target.value));
  };

  useEffect(() => {
    const prefetchTrackData = async () => {
      try {
        const response = await axios.post(`${apiUrl}/api/current_races/batch`, {
          trackIds: tracks.map((track) => track.id), // Pass track IDs in the request body
        });
  
        setPrefetchedRaces(response.data);
      } catch (error) {
        console.error('Error prefetching track data:', error);
      }
    };
  
    if (tracks.length > 0) prefetchTrackData();
  }, [tracks]);
  

  const [prefetchedRaces, setPrefetchedRaces] = useState([]);
  

  const handleTrackClick = async (id) => {
    try {
      const trackResponse = await axios.get(`${apiUrl}/api/tracks/${id}`);
      const selectedTrack = trackResponse.data;
      setTrackId(id);
      setCurrentRaceId(selectedTrack[`race_id1`]);
      setTrackName(selectedTrack.track_name);
      const dailyRaces = selectedTrack.daily_races;
  
      const url = `${apiUrl}/api/query/current_races/track_id/${id}`;
      const currentRaceResponse = await axios.get(url);
      const currentRaces = currentRaceResponse.data;
      const todayDate = new Date().toISOString().split("T")[0];
  
      const racesForToday = currentRaces.filter(
        (race) => race.track_id === id && race.race_date.split("T")[0] === todayDate
      );
  
      if (racesForToday.length > 0) {
        navigate(`/track-card?id=${id}&daily_races=${dailyRaces}`);
        return;
      }
  
      for (let i = 1; i <= dailyRaces; i++) {
        const foundRace = currentRaces.find((race) => race.race_id === i);
        let currentNumHorses;
  
        if (!foundRace) {
          // **Begin Modification Here**
  
          // If race_id is not set for this track, fetch a random race
          if (!selectedTrack[`race_id${i}`] || selectedTrack[`race_id${i}`] < 1) {
            const randomRaceResponse = await axios.get(`${apiUrl}/api/random-records/races/1`);
            const randomRaceId = randomRaceResponse.data[0].id;
            selectedTrack[`race_id${i}`] = randomRaceId;
            const randomRaceData = randomRaceResponse.data[0];
            currentNumHorses = randomRaceData.num_horses;
          }
  
          // **Add This New Code to Ensure num_horses is Set**
          // Set default num_horses if not already set
          if (!currentNumHorses) {
            currentNumHorses = Math.floor(Math.random() * 7) + 6; // Random between 6 and 12
          }
  
          // const raceData = {
          //   race_id: selectedTrack[`race_id${i}`],
          //   track_id: id,
          //   race_date: todayDate,
          //   location: selectedTrack.location,
          //   miles: selectedTrack.miles,
          //   furlongs: selectedTrack.furlongs,
          //   meters: selectedTrack.meters,
          //   num_horses: currentNumHorses,
          //   num_jockeys: currentNumHorses,
          //   post_time: selectedTrack.post_time || "12:00:00", // Set a default post time if needed
          //   weather: selectedTrack.weather || "Clear",        // Set default weather if needed
          //   surface_type: selectedTrack.surface_type || "Turf", // Set default surface type if needed
          //   track_condition: selectedTrack.track_condition || "Fast", // Set default track condition if needed
          //   purse: selectedTrack.purse || 0, // Set default purse if needed
          // };
  
          const raceData = {
            race_id: selectedTrack[`race_id${i}`],
            track_id: id,
            race_date: todayDate,
            location: selectedTrack.location,
            miles: selectedTrack.miles,
            furlongs: selectedTrack.furlongs,
            meters: selectedTrack.meters,
            num_horses: currentNumHorses,
            num_jockeys: currentNumHorses,
            post_time: selectedTrack.post_time || "12:00:00",
            weather: selectedTrack.weather || "Clear",
            surface_type: selectedTrack.surface_type || "Turf",
            track_condition: selectedTrack.track_condition || "Fast",
            purse: selectedTrack.purse || 0,
          };
                   
          await axios.post(`${apiUrl}/api/current_races`, raceData);
          await axios.patch(`${apiUrl}/api/tracks/${id}`, selectedTrack);
  
          // **End of Modification**
        }
      }
      setSelectedTrack(selectedTrack);
      setRace(selectedTrack);
      setTrackName(selectedTrack.track_name);
      setCurrentRaceId(selectedTrack[`race_id${id}`]);
    } catch (error) {
      console.error("Error occurred during race creation:", error);
    }
  };
  
  const handleGoBack = () => {
    setSelectedTrack(null);
  };

  const handlePageRefresh = () => {
    window.location.reload();
  };

  const navigateHandler = () => {
    let url = `/track-card?id=${selectedTrack.id}&daily_races=${selectedTrack.daily_races}`;
    setCurrentRaceId(selectedTrack.id);
    setNavigateData({ currentRaceId: selectedTrack.id, dailyRaces: selectedTrack.daily_races });
    navigate(url);
  };


  const handleWeeklyRacesClick = () => {
    if (selectedTrack) {
      // console.log("Navigating to weekly races for Track ID:", selectedTrack.id);
      // console.log("Selected Track Details:", selectedTrack);
      navigate(`/track-race-dates/${selectedTrack.id}`);
    }
  };
  

  useEffect(() => {
    axios
      .get(`${apiUrl}/api/images`)
      .then((response) => setImages(response.data))
      .catch((error) => console.log(error));
  }, []);

  const filteredTracks = tracks
    .filter((track) => track.location.startsWith(selectedLetter))
    .slice(0, numRows);



  return (
    <div className="program-card-wrapper" style={{marginLeft:"1vw", marginTop: "8vh" }}>
      <h1 className="page-titles"> Program Card </h1>
      <div className="clock-align">
      <Clock showCountdown={false} />
      </div>
      <button onClick={handlePageRefresh} id="reset">
        Reset Page
      </button>
      <div className="num-rows-control">
        <label htmlFor="numRows">Number of Tracks:</label>
        <select
          id="numRows"
          name="numRows"
          value={numRows}
          onChange={handleNumRowsChange}
          style={{ width: '8vw' }}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>

      <h2 className="tracks-location-title"> Track Directory by Location </h2>
      <div className="letters-wrapper">
        <div className="letter-space">
          {letters.split("").map((letter) => (
            <button
              id="letter-space"
              key={letter}
              onClick={() => handleLetterClick(letter)}
              className={letter === selectedLetter ? "clicked" : ""}
              disabled={letter === selectedLetter}
            >
              {letter}
            </button>
          ))}
        </div>
      </div>

      <div className="horse-image-container">
        <figure className="programcard-figure-wrapper">
          <img src={horseImage} alt="horse" className="programcard-figure" />
        </figure>
      </div>
      <div>
       
 {selectedTrack && (
<button onClick={handleWeeklyRacesClick}>
View Weekly Races for {selectedTrack.track_name}
</button>
)}

          </div>
      {selectedTrack && (
        <div className="button-container">
          <button className="daily-races" onClick={navigateHandler}>
            {selectedTrack.track_name} - {selectedTrack.daily_races} - DAILY RACES
          </button>
          
        </div>
      )}

      {selectedTrack && (
        <div className="button-container">
          <button onClick={handleGoBack} className="track-name-close-button">
            <div className="track-name-container">
              <div className="track-name-title">
                CITY BEGINNING WITH LETTER "{selectedLetter}"
                <div className="track-close-button">Close</div>
              </div>
            </div>
          </button>
        </div>
      )}

      {selectedTrack && (
        <div className="card-wrapper">
          <h3>{selectedTrack.track_name}</h3>
          <table>
            <tbody>
              {Object.entries(selectedTrack)
                .slice(2, 10)
                .map(([key, value]) => (
                  <tr key={key}>
                    <th><FormatKey keyText={key} /></th>
                    <td>{value}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}

      {filteredTracks.length > 0 && (
        <div className="tracks">
          {filteredTracks.map((track) => (
            <div key={track.id}>
              <h3
                onClick={() => {
                  handleTrackClick(track.id);
                  window.scrollTo(0, 46);
                }}
              >
                {track.track_name}
              </h3>
              <p>{track.location}</p>
              <p>Daily Races: {track.daily_races}</p>
            </div>
          ))}
        </div>
      )}

      {filteredTracks.length === 0 && selectedLetter && (
        <>
          <p>First Reload could take a few seconds...</p>
          Looking for tracks with locations that begin with the letter: '{selectedLetter}'....
        </>
      )}
    </div>
  );
};

export default ProgramCard;

