import React, { createContext, useContext, useState, useEffect } from "react";

const PreLoadContext = createContext();

export const usePreLoad = () => useContext(PreLoadContext);

export const PreLoadProvider = ({ triggerLoad, children }) => {
  const [loadedAssets, setLoadedAssets] = useState([]);
  const [loadingErrors, setLoadingErrors] = useState([]);

  useEffect(() => {
    if (!triggerLoad) return;

    const assetsToPreload = [
      { type: "video", src: "start.mp4" },
      { type: "video", src: "startgate.mp4" },
      { type: "video", src: "track.mp4" },
    ];

    const timeoutMs = 15000;

    const loadAssetWithTimeout = ({ type, src }) =>
      new Promise((resolve, reject) => {
        let asset;
        let timer;

        if (type === "image") {
          asset = new Image();
          asset.onload = () => resolve(src);
          asset.onerror = () => reject(new Error(`Failed to load: ${src}`));
          asset.src = `/images/${src}`; // Load from public/images
        } else if (type === "video") {
          asset = document.createElement("video");
          asset.oncanplaythrough = () => resolve(src);
          asset.onerror = () => reject(new Error(`Failed to load: ${src}`));
          asset.src = `/video/${src}`; // Load from public/video
          asset.load(); // For videos, start the load process
        } else {
          reject(new Error(`Unsupported asset type: ${type}`));
          return;
        }

        timer = setTimeout(() => {
          reject(new Error(`Timeout reached for asset: ${src}`));
        }, timeoutMs);

        // Cleanup timeout when the asset loads or fails
        asset.onload = asset.onerror = asset.oncanplaythrough = () => {
          clearTimeout(timer);
        };
      });

    const preloadAssets = async () => {
      const results = await Promise.allSettled(
        assetsToPreload.map(loadAssetWithTimeout)
      );

      const successful = results
        .filter((result) => result.status === "fulfilled")
        .map((result) => result.value);
      const failed = results
        .filter((result) => result.status === "rejected")
        .map((result) => result.reason);

      setLoadedAssets(successful);
      setLoadingErrors(failed);

      console.log(`Assets loaded: ${successful.length}`);
      if (failed.length > 0) console.error("Asset errors:", failed);
    };

    preloadAssets();
  }, [triggerLoad]);

  return (
    <PreLoadContext.Provider value={{ loadedAssets, loadingErrors }}>
      {children}
    </PreLoadContext.Provider>
  );
};
