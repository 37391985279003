// src/Components/Modules/Race.js

import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router";
import { RaceContext } from "./RaceContext";
import RaceResultsHandler from "./RaceResultsHandler";
import { config } from "../../config";
import { UnifiedPreloadProvider } from "../Modules/UnifiedPreloadContext"; // <-- we import the provider
import "../Styles/Race.css";

// EXACTLY as before: you had a function signature like: 
// function Race({ startImage, gateImage, trackImage }) {
function Race() {
  const [finishOrder, setFinishOrder] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const {
    currentRaceId,
    race,
    setRace,
    selectedHorseIds,
    selectedHorseNames,
    setSelectedHorseNames,
  } = useContext(RaceContext);

  const [images, setImages] = useState([]);      // <--- We keep these states because your code references them
  const [showTrack, setShowTrack] = useState(false);
  const [loading, setLoading] = useState(true);
  const [horseIds, setHorseIds] = useState(selectedHorseIds);
  const [horseSpeeds, setHorseSpeeds] = useState([]);
  const [horseDurations, setHorseDurations] = useState([]);
  const [numHorses, setNumHorses] = useState(9);
  const [selectedHorseNumbers, setSelectedHorseNumbers] = useState([]);
  const [wirePosition, setWirePosition] = useState(0);
  const [horsePositions, setHorsePositions] = useState([]);
  const [winnerDeclared, setWinnerDeclared] = useState(false);
  const raceTrackRef = useRef(null);
  const [leftEdge, setLeftEdge] = useState(0);
  const [rightEdge, setRightEdge] = useState(0);
  const [winnerNumber, setWinnerNumber] = useState(null);
  const [winnerName, setWinnerName] = useState(null);
  const [raceStartTime, setRaceStartTime] = useState(null);
  const [raceEndTime, setRaceEndTime] = useState(null);
  const [raceDuration, setRaceDuration] = useState(null);
  const [horseTimes, setHorseTimes] = useState([]);
  const raceResultSubmitted = useRef(false);

  // Fetch race data: EXACTLY as before
  const fetchRace = async () => {
    try {
      const response = await axios.get(
        `${config.backendUrl}/api/current_races/${currentRaceId}`
      );
      const raceData = response.data;
  
      setRace(raceData); // Set race data in context
      setNumHorses(raceData.num_horses); // Update number of horses
    } catch (error) {
      console.error("Error fetching race data:", error);
    }
  };
  

  // We keep your original effect that logs the race start time
  useEffect(() => {
    if (raceStartTime) {
      console.log("Race started at:", raceStartTime);
    }
  }, [raceStartTime]);

  useEffect(() => {
    const setupRace = () => {
      setTimeout(() => {
        setShowTrack(true);
        setRaceStartTime(performance.now());
      }, 3000);
    };

    const initializeRace = async () => {
      await fetchRace();

      // EXACT same random speed/durations
      // We keep them so your race logic is unchanged
      const initialSpeeds = Array.from({ length: numHorses }, () =>
        Math.random() * 1600 + 700
      );
      setHorseSpeeds(initialSpeeds);

      const randomDurations = Array.from({ length: numHorses }, () =>
        (Math.random() * 40 + 60).toFixed(2)
      );
      setHorseDurations(randomDurations);

      // No local fetchImages() call, because we’ll get them from context
      // Done. We now do the same final steps:
      setLoading(false);
      setupRace();
    };

    document.body.style.overflow = "hidden";
    initializeRace();

    return () => {
      document.body.style.overflow = "";
    };
  }, [numHorses, currentRaceId]);

  // EXACT same effect that updates horse positions & speeds
  useEffect(() => {
    const targetPosition = window.innerWidth * 0.98;
    let intervalId;

    const updateHorsePositions = () => {
      setHorsePositions((prevPositions) => {
        // If empty, initialize
        if (prevPositions.length === 0) {
          return Array.from({ length: numHorses }, (_, index) => ({
            index,
            position: 0,
            timestamp: performance.now(),
            number: index + 1,
            name: selectedHorseNames[index] || `Horse ${index + 1}`,
          }));
        }

        return prevPositions.map((horse, i) => {
          let newPos = horse.position + horseSpeeds[i] * 0.05;
          return {
            ...horse,
            position: newPos,
          };
        });
      });

      setFinishOrder((prevOrder) => {
        let newlyFinished = [];
        let updatedOrder = [...prevOrder];

        horsePositions.forEach((horse, i) => {
          if (
            horse.position >= targetPosition &&
            !updatedOrder.some((f) => f.index === i)
          ) {
            newlyFinished.push({
              ...horse,
              timestamp: performance.now(),
              duration: (performance.now() - (raceStartTime || 0)) / 1000,
            });
          }
        });

        if (newlyFinished.length > 0) {
          updatedOrder = [...updatedOrder, ...newlyFinished].sort(
            (a, b) => a.timestamp - b.timestamp
          );
        }

        if (
          updatedOrder.length === horsePositions.length &&
          horsePositions.length > 0
        ) {
          setWinnerDeclared(true);
          setRaceEndTime(performance.now());
        }

        return updatedOrder;
      });
    };

    intervalId = setInterval(updateHorsePositions, 50);

    return () => clearInterval(intervalId);
  }, [
    horsePositions,
    finishOrder,
    raceStartTime,
    raceEndTime,
    selectedHorseNames,
    horseIds,
  ]);

  // EXACT same effect that finishes up & calls the backend
  useEffect(() => {
    if (finishOrder.length === numHorses && horsePositions.length > 0) {
      if (!raceResultSubmitted.current) {
        raceResultSubmitted.current = true;

        setRaceDuration(((raceEndTime || performance.now()) - raceStartTime) / 1000);
        const fetchRaceResultId = async () => {
          try {
            const response = await axios.post(`${config.backendUrl}/api/race_results`, {
              race_id: currentRaceId,
              horse_ids: horseIds,
              horse_names: selectedHorseNames,
              num_horses: numHorses,
              winner_number: finishOrder[0].number,
              finish_order: finishOrder.map((horse) => horse.number),
              race_duration: raceDuration,
            });
            const raceResultId = response.data.race_result_id;
            setTimeout(() => {
              navigate("/race-results", {
                state: { race_result_id: raceResultId },
              });
            }, 5000);
          } catch (error) {
            console.error("Error fetching race result ID:", error);
          }
        };
        fetchRaceResultId();
      }
    }
  }, [
    finishOrder,
    numHorses,
    horsePositions,
    raceResultSubmitted,
    raceEndTime,
    raceStartTime,
    raceDuration,
    currentRaceId,
    horseIds,
    selectedHorseNames,
    navigate,
  ]);

  // EXACT same effect to update scoreboard
  useEffect(() => {
    const updateRaceTracker = () => {
      if (!raceTrackRef.current) return;
      const updatedHorsePositions = Array.from(raceTrackRef.current.children).map(
        (horseElement, index) => {
          const boundingRect = horseElement.getBoundingClientRect();
          return {
            index,
            position: boundingRect.left + boundingRect.width,
          };
        }
      );

      setHorsePositions((prev) => {
        return prev.map((horse) => {
          const newPos = updatedHorsePositions.find((up) => up.index === horse.index);
          if (newPos) {
            return {
              ...horse,
              position: newPos.position,
            };
          }
          return horse;
        });
      });

      const sortedPositions = updatedHorsePositions.sort((a, b) => b.position - a.position);
      setSelectedHorseNames(
        sortedPositions.map((pos, idx) => selectedHorseNames[idx] || `Horse ${idx + 1}`)
      );
      setSelectedHorseNumbers(sortedPositions.map((pos, idx) => idx + 1));
    };

    const intervalId = setInterval(updateRaceTracker, 500);
    return () => clearInterval(intervalId);
  }, [images]); // We keep `[images]` because that’s your original code's dependency

  const containerHeight = 520;

  function getRandomValue(min, max) {
    const range = max - min;
    const variation = range * 9;
    const randomOffset = Math.random() * variation - variation / 0.1;
    return (Math.random() * range + min + randomOffset).toFixed(2);
  }

  // Utility for shuffling an array: EXACT same
  function shuffleArray(array) {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  }

  return (
    <>
      {/* EXACT same RaceResultsHandler usage */}
      <RaceResultsHandler
        currentRaceId={currentRaceId}
        horsePositions={horsePositions}
        winnerDeclared={winnerDeclared}
        horseIds={horseIds}
        numHorses={numHorses}
        horseNames={selectedHorseNames}
        wirePosition={wirePosition}
        winnerNumber={winnerNumber}
        winnerName={winnerName}
        finishOrder={finishOrder}
        raceDuration={raceDuration}
        horseTimes={horseTimes}
      />

      {/* THE ONLY CHANGE: WE WRAP EVERYTHING IN UNIFIEDPRELOADPROVIDER */}
      <UnifiedPreloadProvider>
        {/* We use the function child to retrieve startVideo, gateImage, trackVideo, horseImages */}
        {({ startVideo, gateImage, trackVideo, horseImages }) => (
          <>
            {/* EXACT same background container, fade-out logic, etc. */}
            <div className="race-screens-container">
              <video
                className={`track-left ${showTrack ? "fade-out" : ""}`}
                src={startVideo}
                autoPlay
                muted
                loop
                style={{
                  position: "fixed",
                  width: "100vw",
                  marginTop: "3vh",
                  height: "100vh",
                  objectFit: "cover",
                  zIndex: 3, // EXACT as your original
                }}
              />

              <img
                className={`track-left start-gate ${showTrack ? "fade-out" : ""}`}
                src={gateImage}
                alt="Start Gate"
                style={{
                  position: "fixed",
                  width: "158vw",
                  marginTop: "3vh",
                  marginLeft: "0vw",
                  height: "100vh",
                  objectFit: "fill",
                  zIndex: 6, // EXACT as your original
                }}
              />

              {showTrack && (
                <video
                  className={`track-right ${showTrack ? "fade-in" : ""}`}
                  src={trackVideo}
                  autoPlay
                  muted
                  loop
                  style={{
                    position: "fixed",
                    width: "100vw",
                    marginTop: "3vh",
                    height: "100vh",
                    objectFit: "cover",
                    zIndex: 4, // EXACT as your original
                  }}
                />
              )}
            </div>

            {/* EXACT same "race-screens-container" for the horses */}
            <div className="race-screens-container" style={{ zIndex: 5 }}>
              <figure className="race-screen-race-track" ref={raceTrackRef}>
                {/* WHERE WE USED TO DO `images.map(...)`, 
                    WE NOW DO `horseImages.map(...)`. 
                    Otherwise EXACT code. */}
               {/* Render only the number of horses in the race */}
{horseImages.slice(0, numHorses).map((image, index) => {
  const topPosition = `${(9.5 + index * 0.4) * containerHeight / 100}vh`;
  return (
    <figure
      key={image.id || index}
      className="horse"
      style={{
        position: "absolute",
        top: topPosition,
        left: "-12vw",
        animationDuration: `${horseDurations[index] * 1.3}s`,
        animationTimingFunction: `cubic-bezier(
          ${getRandomValue(0.3, 0.6)},
          ${getRandomValue(0.001, 0.9555)},
          ${getRandomValue(0, 0.799)},
          ${getRandomValue(0, 0.999)})`,
        zIndex: 4,
      }}
    >
      <div>
        <img
          className="race-screen-horse-container"
          src={image.url} // using .url from context
          alt={image.image_name || `Horse ${index + 1}`}
          style={{
            display: "inline-block",
            height: "22vh",
            width: "auto",
          }}
        />
        {/* Horse number overlay */}
        <div
          className="horse-number"
          style={{
            position: "absolute",
            top: "6vh",
            left: "11vw",
            transform: "translate(-50%, -50%)",
            backgroundColor: "transparent",
            border: "none",
            color: "#fff",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          {selectedHorseNumbers[index]}
        </div>
      </div>
    </figure>
  );
})}

              </figure>
            </div>

            {/* EXACT same scoreboard overlay that you had in the commented code */}
            {!loading && showTrack && (
  <div
    className="race-tracker-overlay"
    style={{
      width: "fit-content",
      padding: "1%",
      position: "fixed", // Ensures it stays in place on the screen
      zIndex: 10, // Keeps it visible above other elements
    }}
  >
    <h2 style={{ marginTop: "-8vh" }}>RaceTracker</h2>
    <h3
      style={{
        paddingLeft: "auto",
        marginBottom: "-1vh",
        marginTop: "-2vh",
      }}
    >
      Race #{currentRaceId}
    </h3>
    {/* Column Titles */}
    <ul
      style={{
        listStyleType: "none",
        fontWeight: "bold",
        marginBottom: "1vh",
      }}
    >
      <li>
        <span style={{ marginRight: "20px" }}>Post #</span>
        <span style={{ marginRight: "20px" }}>Horse #</span>
        <span>Name</span>
      </li>
    </ul>

    {/* Horse Information */}
    <ul>
      {selectedHorseNames
        .map((horseName, index) => ({
          postNumber: index + 1,
          horseNumber: selectedHorseNumbers[index],
          horseName,
        }))
        .sort((a, b) => a.postNumber - b.postNumber)
        .map((horse, index) => (
          <li key={index}>
            <span style={{ marginRight: "20px" }}>{horse.postNumber}</span>
            <span style={{ marginRight: "20px" }}>{horse.horseNumber}</span>
            <span>{horse.horseName}</span>
          </li>
        ))}
    </ul>

                {/* EXACT same top-4 finishers logic */}
                <div style={{ zIndex: 99999, top: "28vh" }}>
                  {winnerDeclared && <p></p>}
                  <ul>
                    {finishOrder.slice(0, 4).map((horse, index) => {
                      let position;
                      switch (index) {
                        case 0:
                          position = " WINNER ";
                          break;
                        case 1:
                          position = "2nd";
                          break;
                        case 2:
                          position = "3rd";
                          break;
                        case 3:
                          position = "4th";
                          break;
                        default:
                          position = `${index + 1}th`;
                      }
                      return (
                        <li key={horse.index} style={{ listStyleType: "none" }}>
                          {position}: #{horse.number} - {horse.name}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}

            {/* EXACT same finish line wire */}
            <div
              id="wire"
              style={{
                position: "absolute",
                top: "35vh",
                right: "2%",
                width: ".5vw",
                height: "30vh",
                background: "linear-gradient(to bottom, black, white)",
              }}
            ></div>
          </>
        )}
      </UnifiedPreloadProvider>
    </>
  );
}

export default Race;





