import React, { useState } from 'react';
import axios from 'axios';


const apiUrl = process.env.REACT_APP_API_BASE_URL;


function AddColumns() {
  const [sourceTable, setSourceTable] = useState('');
  const [sourceColumns, setSourceColumns] = useState([]);
  const [destTable, setDestTable] = useState('');
  const [destColumns, setDestColumns] = useState([]);
  const [createdColumns, setCreatedColumns] = useState([]);
  const [isComplete, setIsComplete] = useState(false);

  const getColumns = async (tableName, setColumns) => {
    try {
      const response = await axios.get(`${apiUrl}/api/${tableName}/fields`);
      setColumns(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const compareColumns = async () => {
    setIsComplete(false);
    setCreatedColumns([]);
    const createdCols = [];
    for (const col of sourceColumns) {
      if (!destColumns.includes(col)) {
        try {
          const response = await axios.post(`${apiUrl}/api/${destTable}/addColumn`, { columnName: col });
          createdCols.push(col);
          console.log(`Created column '${col}' in '${destTable}'`);
        } catch (error) {
          console.error(error);
        }
      }
    }
    setCreatedColumns(createdCols);
    setIsComplete(true);
  };

  return (
    <div>
      <h2>Compare Columns - USE CAUTION</h2>
      <h3>THIS WILL ADD MISSING COLUMNS FROMM SOURCE TO THE DESTINATION TABLE
        <p>IT WILL MAKE ALL TEXT DATA TYPES FOR NOW</p>
      </h3>
      <label htmlFor="sourceTableInput">Source Table:</label>
      <input
        id="sourceTableInput"
        type="text"
        value={sourceTable}
        onChange={(event) => setSourceTable(event.target.value)}
      />
      <button onClick={() => getColumns(sourceTable, setSourceColumns)}>Get Columns</button>
      <ul>
        {sourceColumns.map((col) => (
          <li key={col}>{col}</li>
        ))}
      </ul>

      <label htmlFor="destTableInput">Destination Table:</label>
      <input
        id="destTableInput"
        type="text"
        value={destTable}
        onChange={(event) => setDestTable(event.target.value)}
      />
      <button onClick={() => getColumns(destTable, setDestColumns)}>Get Columns</button>
      <ul>
        {destColumns.map((col) => (
          <li key={col}>{col}</li>
        ))}
      </ul>
      <button onClick={compareColumns}>Add Columns to Destination</button>
      {isComplete && (
        <p>
          Created columns:{' '}
          {createdColumns.length === 0 ? 'None' : createdColumns.map((col) => `'${col}'`).join(', ')}
        </p>
      )}
    </div>
  );
}

export default AddColumns;






// import React, { useState } from 'react';
// import axios from 'axios';

// function CompareColumns() {
//   const [sourceTable, setSourceTable] = useState('');
//   const [sourceColumns, setSourceColumns] = useState([]);
//   const [destTable, setDestTable] = useState('');
//   const [destColumns, setDestColumns] = useState([]);
//   const [createdColumns, setCreatedColumns] = useState([]);
//   const [isComplete, setIsComplete] = useState(false);

//   const getColumns = async (tableName, setColumns) => {
//     try {
//       const response = await axios.get(`${apiUrl}/api/${tableName}/fields`);
//       setColumns(response.data);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const compareColumns = async () => {
//     setIsComplete(false);
//     setCreatedColumns([]);
//     const createdCols = [];
//     for (const col of sourceColumns) {
//       if (!destColumns.includes(col)) {
//         try {
//           const response = await axios.post(`${apiUrl}/api/${destTable}/addColumn`, { columnName: col });
//           createdCols.push(col);
//           console.log(`Created column '${col}' in '${destTable}'`);
//         } catch (error) {
//           console.error(error);
//         }
//       }
//     }
//     setCreatedColumns(createdCols);
//     setIsComplete(true);
//   };

//   const getColumnDiff = () => {
//     const diff = sourceColumns.filter((col) => !destColumns.includes(col));
//     return diff.length === 0 ? 'None' : diff.map((col) => `'${col}'`).join(', ');
//   };

//   return (
//     <div>
//       <h2>Compare Columns</h2>
//       <div style={{ display: 'flex', flexDirection: 'row' }}>
//         <div style={{ flex: 1 }}>
//           <label htmlFor="sourceTableInput">Source Table:</label>
//           <input
//             id="sourceTableInput"
//             type="text"
//             value={sourceTable}
//             onChange={(event) => setSourceTable(event.target.value)}
//           />
//           <button onClick={() => getColumns(sourceTable, setSourceColumns)}>Get Columns</button>
//           <ul>
//             {sourceColumns.map((col) => (
//               <li key={col}>{col}</li>
//             ))}
//           </ul>
//         </div>
//         <div style={{ flex: 1 }}>
//           <label htmlFor="destTableInput">Destination Table:</label>
//           <input
//             id="destTableInput"
//             type="text"
//             value={destTable}
//             onChange={(event) => setDestTable(event.target.value)}
//           />
//           <button onClick={() => getColumns(destTable, setDestColumns)}>Get Columns</button>
//           <ul>
//             {destColumns.map((col) => (
//               <li key={col}>{col}</li>
//             ))}
//           </ul>
//         </div>
//         <div style={{ flex: 1 }}>
//           <label>Column Diff:</label>
//           <p>{getColumnDiff()}</p>
//         </div>
//       </div>
//       <button onClick={compareColumns}>Add Columns to Destination</button>
//       {isComplete && (
//         <p>
//           Created columns:{' '}
//           {createdColumns.length === 0 ? 'None' : createdColumns.map((col) => `'${col}'`).join(', ')}
//         </p>
//       )}
//     </div>
//   );
// }

// export default CompareColumns;

