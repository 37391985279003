import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import FormatKey from "../Modules/FormatKey";
import { RaceContext } from "../Pages/RaceContext";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const AdvancedBetTracks = () => {
  const [loadingRaceDetails, setLoadingRaceDetails] = useState(false);
  const location = useLocation();
  const { trackName = "Unknown", selectedBet } = location.state || {};  // Add fallback for trackName
  const navigate = useNavigate();
  const [track, setTrack] = useState(null);
  const [races, setRaces] = useState([]);
  const [selectedRaces, setSelectedRaces] = useState([]);
  const [numColumns, setNumColumns] = useState(6); // Default number of columns
  const [errorMessage, setErrorMessage] = useState("");
  const { setCurrentRaceId, setNumHorses, setRace } = useContext(RaceContext);

  const betTypeLimits = {
    "Pick 3": 3,
    "Pick 4": 4,
    "Pick 5": 5,
    "Pick 6": 6,
    "Pick 7": 7,
    "Pick 8": 8,
    "Pick 9": 9,
    "Pick 10": 10,
    "Daily Double": 2,
  };

  const requiredSelections = betTypeLimits[selectedBet] || 0;

  useEffect(() => {
    if (!trackName) {
      setErrorMessage("No track name provided.");
      return;
    }

    const fetchTrackAndRaces = async () => {
      try {
        console.log("Fetching track data for track name:", trackName);

        // Fetch the track data
        const trackResponse = await axios.get(`${apiUrl}/api/tracks?track_name=${trackName}`);
        const trackData = trackResponse.data.find(track => track.track_name === trackName);

        if (trackData) {
          setTrack(trackData);  // Set track data

          // Fetch races for the track if not already available
          const raceIds = Object.keys(trackData)
            .filter(key => key.startsWith("race_id") && trackData[key])
            .map(key => trackData[key]);

          if (raceIds.length === 0) {
            const raceResponse = await axios.get(`${apiUrl}/api/races?limit=${trackData.daily_races}`);
            const races = raceResponse.data.map(race => race.id);
            setRaces(races);
          } else {
            // Fetch detailed races using race IDs
            const racePromises = raceIds.map(async raceId => {
              try {
                const raceResponse = await axios.get(`${apiUrl}/api/races/${raceId}`);
                return raceResponse.data;
              } catch (error) {
                console.error(`Error fetching race with ID ${raceId}:`, error);
                return null;
              }
            });
            const fetchedRaces = await Promise.all(racePromises);
            setRaces(fetchedRaces.filter(race => race !== null));
          }
        } else {
          console.error("No track data found for the given track name.");
          setErrorMessage("No track data found.");
        }
      } catch (error) {
        console.error("Error fetching track data:", error);
        setErrorMessage("An error occurred while fetching track data.");
      }
    };

    fetchTrackAndRaces();
  }, [trackName]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCheckboxChange = raceId => {
    setSelectedRaces(prevSelected => {
      if (prevSelected.includes(raceId)) {
        return prevSelected.filter(id => id !== raceId);
      } else {
        return [...prevSelected, raceId];
      }
    });
  };

  const handleAdvancedBets = () => {
    if (selectedRaces.length === requiredSelections) {
      navigate("/advanced-bet-races", {
        state: { selectedRaces, trackName, selectedBet },
      });
    } else {
      setErrorMessage(`Please select exactly ${requiredSelections} races for the ${selectedBet} bet type.`);
    }
  };

  const handleNumColumnsChange = event => {
    setNumColumns(parseInt(event.target.value, 10));
  };

  return (
    <div>
      <button onClick={handleGoBack} style={{ marginTop: "4vh" }}>
        Go Back
      </button>
      <h1>SELECT MULTIPLE RACES</h1>
      <h2>TRACK CARD:</h2> 
      <h3>Selected Bet Type: {selectedBet}</h3> {/* Display selected bet type */}

      {track ? (
        <div>
          <table>
            <thead>
              <tr>
                {Object.keys(track)
                  .slice(0, numColumns)
                  .map(key => (
                    <th key={key}><FormatKey keyText={key} /></th>
                  ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {Object.keys(track)
                  .slice(0, numColumns)
                  .map(key => (
                    <td key={key}>{track[key]}</td>
                  ))}
              </tr>
            </tbody>
          </table>

          <h2>Today's Current Races:</h2>

          <button
            onClick={handleAdvancedBets}
            style={{ marginTop: "20px", display: "block", marginLeft: "auto", marginRight: "auto" }}
          >
            Proceed to Advanced Bets
          </button>

          <label>
            Number of columns to display:
            <select
              value={numColumns}
              onChange={handleNumColumnsChange}
              style={{ marginLeft: "1%", width: "8%" }}
            >
              {Object.keys(races[0] || {}).map((_, index) => (
                <option key={index} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>
          </label>

          {errorMessage && (
            <p style={{ color: "red", textAlign: "center", marginTop: "10px" }}>{errorMessage}</p>
          )}

          {races.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Select</th>
                  {Object.keys(races[0])
                    .slice(0, numColumns)
                    .map(key => (
                      <th key={key}>
                        <FormatKey keyText={key} />
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {races.map(race => (
                  race && (
                    <tr key={race.id}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedRaces.includes(race.id)}
                          onChange={() => handleCheckboxChange(race.id)}
                        />
                      </td>
                      {Object.keys(race)
                        .slice(0, numColumns)
                        .map(key => (
                          <td key={key}>{race[key]}</td>
                        ))}
                    </tr>
                  )
                ))}
              </tbody>
            </table>
          ) : (
            <p>Loading races...</p>
          )}

          {loadingRaceDetails && <p>Loading race details...</p>}
        </div>
      ) : (
        <p>Retrieving track information...</p>
      )}
    </div>
  );
};

export default AdvancedBetTracks;
