// src/Components/Modules/UnifiedPreloadContext.js
import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { config } from "../../config";

// (1) Create a context
const UnifiedPreloadContext = createContext(null);

const preloadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    const encodedSrc = encodeURI(src); // Encode the URL to handle spaces and special characters
    // console.log(`Preloading image from: ${encodedSrc}`); // Debug log for encoded URL
    img.onload = () => resolve(src);
    img.onerror = (error) => {
      console.error(`Failed to load image: ${encodedSrc}`, error);
      reject(new Error(`Failed to load image: ${encodedSrc}`));
    };
    img.src = encodedSrc;
  });
};

const preloadVideo = (src) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      let timeoutId = setTimeout(() => {
        console.error(`Timeout reached for asset: ${src}`);
        reject(new Error(`Timeout reached for asset: ${src}`));
      }, 10000); // Increase timeout to 10 seconds
  
      video.src = src;
      video.oncanplaythrough = () => {
        clearTimeout(timeoutId);
        resolve(src);
      };
      video.onerror = () => {
        clearTimeout(timeoutId);
        console.error(`Failed to load video: ${src}`);
        reject(new Error(`Failed to load video: ${src}`));
      };
      video.load();
    });
  };
  

// (3) UnifiedPreloadProvider component
export const UnifiedPreloadProvider = ({ children }) => {
  const [loaded, setLoaded] = useState(false);
  const [envAssets, setEnvAssets] = useState({
    startVideo: null,
    gateImage: null,
    trackVideo: null,
  });
  const [horseImages, setHorseImages] = useState([]);
  const [pics, setPics] = useState([]); // Initialize pics state
  const [error, setError] = useState(null);

  // (4) Load environment assets and horse images
  useEffect(() => {
    const loadEnvAssets = async () => {
      try {
        // Load videos and images in parallel from public/videos
        const [startVid, gateImg, trackVid] = await Promise.all([
          preloadVideo(`${config.backendUrl}/videos/start.mp4`),
          preloadImage(`${config.backendUrl}/videos/gate.png`),
          preloadVideo(`${config.backendUrl}/videos/track.mp4`),
        ]);

        setEnvAssets({
          startVideo: startVid,
          gateImage: gateImg,
          trackVideo: trackVid,
        });
      } catch (err) {
        console.error("Failed to preload environment assets:", err);
        setError(err);
      }
    };
      
    const loadHorseImages = async () => {
      try {
        // console.log("Starting to load horse images...");
    
        const response = await axios.get(`${config.backendUrl}/api/images`);
        // console.log("API response for horse images:", response.data);
    
        const allImages = response.data;
        if (!allImages || allImages.length === 0) {
          console.warn("No images returned from the API.");
          setHorseImages([]);
          return;
        }
    
        // console.log("Preloading horse images:", allImages);
        await Promise.all(
          allImages.map((imgObj) => {
            // console.log(`Attempting to preload image: ${imgObj.url}`);
            return preloadImage(imgObj.url);
          })
        );
    
        setHorseImages(allImages);
        // console.log("Successfully loaded and set horse images:", allImages);
      } catch (err) {
        console.error("Error occurred while loading horse images:", err);
        setError(err);
      }
    };
    




    const loadPics = async () => {
      try {
        const response = await axios.get(`${config.backendUrl}/api/pics`);
        const pics = response.data;
    
        if (!pics || pics.length === 0) {
          console.warn("No pics returned from the API.");
          return;
        }
    
        await Promise.all(
          pics.map((picObj) => preloadImage(picObj.url))
        );
    
        setPics(pics); // Set pics after preloading
        console.log("Pics preloaded successfully.");
      } catch (err) {
        console.error("Error loading pics:", err);
        setError(err); // Use the existing setError
      }
    };
    







  //   const loadAll = async () => {
  //     await Promise.all([loadEnvAssets(), loadHorseImages()]);
  //     setLoaded(true); // Mark all assets as loaded
  //   };

  

  const loadAll = async () => {
    await Promise.all([loadEnvAssets(), loadHorseImages(), loadPics()]); // Add loadPics here
    setLoaded(true); // Original behavior remains
  };
  

    loadAll();
  }, []);












  // (5) Show a fallback while assets are loading
  if (!loaded) {
    return <div>Loading all assets... please wait.</div>;
  }

  // (6) Pass loaded assets to children via context
  return (
    <UnifiedPreloadContext.Provider value={{ envAssets, horseImages, pics, error }}>
      {typeof children === "function"
        ? children({ ...envAssets, horseImages, pics })
        : children}
    </UnifiedPreloadContext.Provider>
  );
};

// Export the context
export default UnifiedPreloadContext;

